define("gestor-projetos/adapters/access-profile", ["exports", "gestor-projetos/adapters/application", "gestor-projetos/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AccessProfileAdapter extends _application.default {
    urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return `${_environment.default.apiUrl.core}/accessProfiles/me`;
      }
      let originalUrl = super.urlForQueryRecord(...arguments);
      return originalUrl;
    }
  }
  _exports.default = AccessProfileAdapter;
});