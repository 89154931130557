define("gestor-projetos/pods/task/records/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1o5cMVzo",
    "block": "{\"symbols\":[\"workRecord\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"list-group list-group-flush\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"task\",\"workRecords\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"list-group-item d-flex\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex-shrink-0\"],[8],[0,\"\\n        \"],[5,\"ui-avatar\",[[12,\"class\",\"avatar-4 mr-3\"],[12,\"alt\",[23,1,[\"user\",\"fullName\"]]]],[[\"@src\"],[[23,1,[\"user\",\"avatar\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"flex-fill align-self-center\"],[8],[0,\"\\n        \"],[7,\"h6\",true],[10,\"class\",\"mb-0\"],[8],[0,\"\\n          \"],[7,\"strong\",true],[8],[1,[23,1,[\"user\",\"fullName\"]],false],[9],[0,\"\\n          registrou \"],[1,[23,1,[\"hours\"]],false],[0,\" hora(s) e \"],[1,[23,1,[\"minutes\"]],false],[0,\" minuto(s) -\\n\\n          \"],[7,\"small\",true],[10,\"class\",\"text-muted\"],[11,\"title\",[28,\"moment-format\",[[23,1,[\"date\"]],\"DD/MM/YYYY HH:mm\"],null]],[8],[0,\"\\n            \"],[1,[28,\"moment-from-now\",[[23,1,[\"date\"]]],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[10,\"class\",\"text-mentions\"],[8],[0,\"\\n          \"],[1,[28,\"show-mentions\",null,[[\"text\",\"breakLines\",\"mentions\"],[[23,1,[\"comment\"]],true,[23,1,[\"mentions\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"list-group-item text-center font-italic\"],[8],[0,\"Nenhum registro de trabalho\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/task/records/template.hbs"
    }
  });
  _exports.default = _default;
});