define("gestor-projetos/pods/application/notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ef5a/h3+",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[7,\"li\",false],[12,\"class\",\"navbar-dropdown\"],[3,\"did-insert\",[[23,0,[\"initNotifications\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"notifications\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row notification-dropdown\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-left mb-0 notification-dropdown-item\"],[8],[0,\"Notificações\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-right mb-0\"],[8],[0,\"\\n          \"],[7,\"button\",true],[10,\"class\",\"btn btn-link text-body notification-dropdown-item-2 text-truncate\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"readAllNotifications\"]]],null]],[8],[0,\"\\n            Marcar todas como lidas\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"list-group list-group-flush list-unstyled list-group-size\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"notifications\"]]],null,{\"statements\":[[0,\"      \"],[5,\"application/notification/item\",[],[[\"@notification\",\"@reloadNotifications\"],[[23,1,[]],[28,\"fn\",[[23,0,[\"initNotifications\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"text-center py-3\"],[8],[0,\"Não há novas notificações\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/application/notification/template.hbs"
    }
  });
  _exports.default = _default;
});