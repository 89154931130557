define("gestor-projetos/pods/submit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    actions: {
      createTask() {
        let summary = '[Formulário] ' + $('#summary').val();
        let version = $('#version').val();
        let verifiedBehavior = $('#verifiedBehavior').val();
        let expectedBehavior = $('#expectedBehavior').val();
        let taskDescription = `
**Versão:** ${version}

---
**Comportamento verificado:** ${verifiedBehavior}

---
**Comportamento esperado:** ${expectedBehavior}`;
        this.ajax.post('/tasks', {
          data: {
            task: {
              userCreate: Ember.get(this, 'model.user.id'),
              project: 430,
              taskColumn: 2037,
              title: summary,
              description: taskDescription,
              order: 0
            }
          }
        }).then(data => {
          this.send('closeModal');
          this.ajax.post('taskHistories', {
            data: {
              taskHistory: {
                user: Ember.get(this, 'model.user.id'),
                date: moment().toISOString(),
                task: data.task.id,
                type: 'created'
              }
            }
          });
        });
      }
    }
  });
  _exports.default = _default;
});