define("gestor-projetos/pods/errors/inactive-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CwBJ3Sfl",
    "block": "{\"symbols\":[\"Page\"],\"statements\":[[5,\"ui-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"section\"]],[[12,\"class\",\"text-center\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-meh fa-4x text-primary mb-3\"],[8],[9],[0,\"\\n\\n    \"],[7,\"h2\",true],[8],[0,\"Parece que a conta desta empresa está inativa\"],[9],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"Entre em contato com o RH da sua empresa para maiores detalhes\"],[9],[0,\"\\n\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-success\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"logout\"],null]],[8],[0,\"Voltar\"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/errors/inactive-account/template.hbs"
    }
  });
  _exports.default = _default;
});