define("gestor-projetos/pods/home/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ab8c67y6",
    "block": "{\"symbols\":[],\"statements\":[[5,\"home/form\",[],[[\"@project\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/home/edit/template.hbs"
    }
  });
  _exports.default = _default;
});