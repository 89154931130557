define("gestor-projetos/pods/application/super-user-login/component", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Component.extend((_obj = {
    tagName: '',
    ajaxAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    openModal: false,
    *superUserLogin() {
      try {
        this.session.set('data.keybkp', this.session.data.authenticated);
        yield this.session.authenticate('authenticator:super-user', {
          identification: this.userIdentifier
        });
        location.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "superUserLogin", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_obj, "superUserLogin"), _obj)), _obj));
  _exports.default = _default;
});