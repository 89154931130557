define("gestor-projetos/pods/errors/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('login');
      }
    }
  });
  _exports.default = _default;
});