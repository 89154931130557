define("gestor-projetos/helpers/background-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.backgroundColor = backgroundColor;
  _exports.default = void 0;
  function backgroundColor(params) {
    let color = params[0];
    return new Ember.String.htmlSafe('background-color:' + color);
  }
  var _default = Ember.Helper.helper(backgroundColor);
  _exports.default = _default;
});