define("gestor-projetos/pods/project/index/task/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    async deactivate() {
      this.controller.model.rollbackAttributes();
      Ember.set(this.controller, 'editDescription', false);
      await this.store.findRecord('task', this.currentModel.get('id'));
    }
  });
  _exports.default = _default;
});