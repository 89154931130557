define("gestor-projetos/nela-select/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/nela-select-multiple.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/nela-select-multiple.hbs should pass TemplateLint.\n\naddon/templates/components/nela-select-multiple.hbs\n  4:10  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n  5:12  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n  10:10  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/nela-select.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/nela-select.hbs should pass TemplateLint.\n\naddon/templates/components/nela-select.hbs\n  4:10  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n  5:12  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n  10:10  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
});