define("gestor-projetos/models/task-history", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let TaskHistoryModel = (_dec = (0, _model.attr)('date'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.belongsTo)('checklist'), _dec5 = (0, _model.belongsTo)('checklist-item'), _dec6 = (0, _model.belongsTo)('task'), _dec7 = (0, _model.belongsTo)('task-attachment'), _dec8 = (0, _model.belongsTo)('task-column'), _dec9 = (0, _model.belongsTo)('user'), (_class = class TaskHistoryModel extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "date", _descriptor, this);
      _initializerDefineProperty(this, "progress", _descriptor2, this);
      _initializerDefineProperty(this, "type", _descriptor3, this);
      _initializerDefineProperty(this, "checklist", _descriptor4, this);
      _initializerDefineProperty(this, "checklistItem", _descriptor5, this);
      _initializerDefineProperty(this, "task", _descriptor6, this);
      _initializerDefineProperty(this, "taskAttachment", _descriptor7, this);
      _initializerDefineProperty(this, "taskColumn", _descriptor8, this);
      _initializerDefineProperty(this, "user", _descriptor9, this);
    }
    get text() {
      let name = this.user.get('fullName');
      let checklist = this.checklist.get('title');
      let checklistItem = this.checklistItem.get('title');
      let taskColumn = this.taskColumn.get('title');
      let attachment = this.taskAttachment.get('name');
      if (this.type === 'created-upload') {
        if (!attachment) {
          return `${name} adicionou um anexo`;
        }
        return Ember.String.htmlSafe(`${name} adicionou o anexo <strong>${attachment}</strong>`);
      }
      if (this.type === 'created-checklist') {
        if (!checklist) {
          return `${name} criou um checklist`;
        }
        return Ember.String.htmlSafe(`${name} criou o checklist <strong>${checklist}</strong>`);
      }
      if (this.type === 'moved') {
        let msg = `${name} moveu a tarefa`;
        if (!taskColumn) {
          return msg;
        }
        return Ember.String.htmlSafe(`${msg} para <strong>${taskColumn}</strong>`);
      }
      if (this.type === 'finished-checklist-item') {
        let msg = `${name} finalizou `;
        if (checklistItem) {
          msg += `o item <strong>${checklistItem}</strong> do checklist`;
        } else {
          msg += `um item do checklist`;
        }
        if (checklist) {
          msg += ` <strong>${checklist}</strong>`;
        }
        return Ember.String.htmlSafe(msg);
      }
      if (this.type === 'remove-checklist-item') {
        let msg = `${name} removeu `;
        if (checklistItem) {
          msg += `o item <strong>${checklistItem}</strong> do checklist`;
        } else {
          msg += `um item do checklist`;
        }
        if (checklist) {
          msg += ` <strong>${checklist}</strong>`;
        }
        return Ember.String.htmlSafe(msg);
      }
      if (this.type === 'create-checklist-item') {
        let msg = `${name} criou `;
        if (checklistItem) {
          msg += `o item <strong>${checklistItem}</strong> do checklist`;
        } else {
          msg += `um item do checklist`;
        }
        if (checklist) {
          msg += ` <strong>${checklist}</strong>`;
        }
        return Ember.String.htmlSafe(msg);
      }
      if (this.type === 'open-checklist-item') {
        let msg = `${name} abriu novamente `;
        if (checklistItem) {
          msg += `o item <strong>${checklistItem}</strong> do checklist`;
        } else {
          msg += `um item do checklist`;
        }
        if (checklist) {
          msg += ` <strong>${checklist}</strong>`;
        }
        return Ember.String.htmlSafe(msg);
      }
      if (this.type === 'deleted-checklist') {
        let msg = `${name} removeu `;
        if (checklist) {
          msg += `o checklist <strong>${checklist}</strong>`;
        } else {
          msg += `um checklist`;
        }
        return Ember.String.htmlSafe(msg);
      }
      if (this.type === 'finished-stage') {
        if (!taskColumn) {
          return `${name} concluiu uma etapa`;
        }
        return Ember.String.htmlSafe(`${name} concluiu a etapa <i>${taskColumn}<i>`);
      }
      let texts = {
        approved: `${name} aprovou a tarefa`,
        archived: `${name} arquivou a tarefa`,
        created: `${name} criou a tarefa`,
        'deleted-upload': `${name} removeu um anexo`,
        failed: `${name} reprovou a tarefa`,
        finished: `${name} finalizou a tarefa`,
        progress: `${name} alterou o progresso para ${this.progress}%`,
        unarchived: `${name} desarquivou a tarefa`
      };
      let text = texts[this.type];
      if (!text) {
        return 'Não foi possível recuperar este histórico';
      }
      return text;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "date", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "progress", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "checklist", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "checklistItem", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "task", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "taskAttachment", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "taskColumn", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TaskHistoryModel;
});