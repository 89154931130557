define("gestor-projetos/pods/project/index/controller", ["exports", "ember-concurrency", "sortablejs"], function (_exports, _emberConcurrency, _sortablejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Controllers
    projectController: Ember.inject.controller('project'),
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Single line computed properties
    searchTask: '',
    columnsSorting: Ember.A(['order']),
    project: Ember.computed.alias('model.project'),
    taskColumns: Ember.computed.sort('model.taskColumns', 'columnsSorting'),
    // Multiline computed properties
    isAdminOnProject: Ember.computed('project.usersAdmin.[]', 'session.user.id', function () {
      let admin = this.project.usersAdmin.findBy('id', String(this.session.user.id));
      return Boolean(admin);
    }),
    // Tasks
    handleMove: (0, _emberConcurrency.task)(function* (evt) {
      try {
        let order = evt.newDraggableIndex;
        let column = evt.item.dataset.id;
        column = column.replace('column-', '');
        yield this.ajax.put(`projects/${this.project.id}/moveColumn`, {
          data: {
            column,
            order
          }
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    saveColumn: (0, _emberConcurrency.task)(function* () {
      try {
        this.swal.loading('Criando coluna');
        if (this.createColumn.validations.isInvalid) {
          return this.swal.validation(this.createColumn.validations);
        }
        yield this.createColumn.save();
        Ember.set(this, 'createColumn', null);
        this.toast.success('Coluna adicionada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Custom methods
    autofocus(element) {
      element.focus();
    },
    makeSortable(element) {
      new _sortablejs.default(element, {
        animation: 200,
        direction: 'horizontal',
        draggable: '.draggable',
        group: {
          name: `project-${this.project.id}`,
          pull: false,
          put: false
        },
        sort: this.isAdminOnProject,
        onUpdate: evt => {
          this.handleMove.perform(evt);
        }
      });
    },
    updateSort(element) {
      let sortable = _sortablejs.default.get(element);
      sortable.option('sort', this.isAdminOnProject);
    },
    // Actions
    actions: {
      cancelColumn() {
        this.createColumn.rollbackAttributes();
        Ember.set(this, 'createColumn', null);
      },
      createColumn() {
        let column = this.store.createRecord('task-column', {
          order: this.taskColumns.length,
          project: this.project
        });
        Ember.set(this, 'createColumn', column);
      },
      createOnEnter(_, e) {
        if (e.keyCode === 13) {
          e.preventDefault();
          this.saveColumn.perform();
        }
      }
    }
  });
  _exports.default = _default;
});