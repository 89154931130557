define("gestor-projetos/pods/project/index/badges/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Single line computed properties
    project: Ember.computed.alias('model'),
    // Actions
    actions: {
      closeModal() {
        this.transitionToRoute('project.index');
      },
      createBadge() {
        let badge = this.store.createRecord('badge', {
          color: '#156FB2',
          project: this.project,
          title: 'Nova etiqueta'
        });
        this.project.badges.addObject(badge);
      }
    }
  });
  _exports.default = _default;
});