define("gestor-projetos/pods/project/index/task/edit/attachments/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BG4VXy+r",
    "block": "{\"symbols\":[\"@taskAttachment\"],\"statements\":[[5,\"ui-card/body\",[[12,\"class\",\"p-3 text-truncate text-center position-relative group\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"img\",true],[10,\"class\",\"avatar-4 mb-1\"],[11,\"src\",[28,\"task-attachment\",[[23,1,[\"type\"]]],null]],[11,\"alt\",[23,1,[\"name\"]]],[8],[9],[0,\"\\n\\n  \"],[7,\"h6\",true],[10,\"class\",\"font-weight-bold mb-0 text-truncate\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n  \"],[7,\"small\",true],[8],[1,[23,1,[\"user\",\"fullName\"]],false],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"position-absolute t-0 l-0 w-100 h-100 bg-dark rounded-lg opacity-0 group-hover:opacity-7 transition-sm\"],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"position-absolute t-0 l-0 w-100 h-100 d-flex align-items-end justify-content-center pb-3 opacity-0 group-hover:opacity-10 transition-sm\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-primary mx-2\"],[10,\"title\",\"Baixar\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"download\"]]],null]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-cloud-download\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"allowRemove\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-danger mx-2\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"removeAttachment\"]]],null]],[10,\"title\",\"Remover\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/task/edit/attachments/item/template.hbs"
    }
  });
  _exports.default = _default;
});