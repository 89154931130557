define("gestor-projetos/pods/project/index/badges/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UKeFwc3H",
    "block": "{\"symbols\":[\"Modal\",\"badge\"],\"statements\":[[5,\"ui-modal\",[[12,\"data-backdrop\",\"static\"]],[[\"@open\",\"@onCloseModal\"],[true,[28,\"action\",[[23,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"Etiquetas\"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-right mb-2\"],[8],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-primary\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"createBadge\"],null]],[8],[0,\"\\n        Adicionar etiqueta\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"ul\",true],[10,\"class\",\"list-group\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"project\",\"badges\"]]],null,{\"statements\":[[0,\"        \"],[5,\"project/index/badges/item\",[],[[\"@badge\",\"@project\"],[[23,2,[]],[23,0,[\"project\"]]]]],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"        \"],[7,\"li\",true],[10,\"class\",\"list-group-item text-center font-italic\"],[8],[0,\"\\n          Não há etiquetas cadastradas\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/badges/template.hbs"
    }
  });
  _exports.default = _default;
});