define("gestor-projetos/pods/submit/route", ["exports", "gestor-projetos/mixins/additional-route-models", "gestor-projetos/mixins/modal-route"], function (_exports, _additionalRouteModels, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_additionalRouteModels.default, _modalRoute.default, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    additionalModels: function () {
      return {
        user: this.modelFor('application').user
      };
    }
  });
  _exports.default = _default;
});