define("gestor-projetos/helpers/badge-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.backgroundColor = backgroundColor;
  _exports.default = void 0;
  function backgroundColor(params) {
    let hex = params[0];
    if (hex) {
      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        return new Ember.String.htmlSafe('color:#000000');
      }
      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);
      let color = r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
      return new Ember.String.htmlSafe(`color: ${color}; background-color: #${hex}`);
    } else {
      return new Ember.String.htmlSafe('color: #FFFFFF; background-color: #000000;');
    }
  }
  var _default = Ember.Helper.helper(backgroundColor);
  _exports.default = _default;
});