define("gestor-projetos/pods/components/text-mentions/component", ["exports", "ember-concurrency", "showdown", "gestor-projetos/config/environment"], function (_exports, _emberConcurrency, _showdown, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['text-mentions'],
    // Services
    store: Ember.inject.service(),
    // Lifecycle hooks
    didReceiveAttrs() {
      this._super(...arguments);
      this.processText.perform();
    },
    // Tasks
    processText: (0, _emberConcurrency.task)(function* () {
      const converter = new _showdown.default.Converter();
      const url = `${_environment.default.appUrl.social}profile`;
      let value = this.value || '';
      if (!value) {
        return '';
      }
      try {
        let mentions = value.match(/\[~([^\]]*)\]/g);
        mentions = [...new Set(mentions)];
        for (let mention of mentions) {
          let id = mention.replace(/\[|~|\]/g, '');
          let user = yield this.store.findRecord('user', id);
          let text = `<a href="${url}/${id}" class="font-weight-bold" target='_blank'>${user.fullName}</a>`;
          let escRegExp = mention.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
          value = value.replace(new RegExp(escRegExp, 'g'), text);
        }
        Ember.set(this, 'text', converter.makeHtml(value));
      } catch (e) {
        Ember.debug(`<TextMentions> :: processText :: ${e}`);
      }
    }).restartable()
  });
  _exports.default = _default;
});