define("gestor-projetos/models/task-list", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    company: (0, _model.belongsTo)('company'),
    project: (0, _model.belongsTo)('project'),
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    initialDate: (0, _model.attr)('date'),
    conclusionDate: (0, _model.attr)('date'),
    open: (0, _model.attr)('boolean'),
    tasks: (0, _model.hasMany)('task'),
    startDate: Ember.computed('initialDate', function () {
      var vDate = moment(`${this.initialDate}`);
      if (vDate.isValid()) {
        return vDate.format('DD/MM/YYYY');
      } else {
        return null;
      }
    }),
    startTime: Ember.computed('initialDate', function () {
      var vDate = moment(`${this.initialDate}`);
      if (vDate.isValid()) {
        return vDate.format('HH:mm');
      } else {
        return null;
      }
    }),
    endDate: Ember.computed('conclusionDate', function () {
      var vDate = moment(`${this.conclusionDate}`);
      if (vDate.isValid()) {
        return vDate.format('DD/MM/YYYY');
      } else {
        return null;
      }
    }),
    endTime: Ember.computed('conclusionDate', function () {
      var vDate = moment(`${this.conclusionDate}`);
      if (vDate.isValid()) {
        return vDate.format('HH:mm');
      } else {
        return null;
      }
    })
  });
  _exports.default = _default;
});