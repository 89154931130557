define("gestor-projetos/pods/leaderless/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I06l7dfQ",
    "block": "{\"symbols\":[\"Card\",\"@project\",\"@isAdmin\"],\"statements\":[[5,\"ui-card\",[[12,\"class\",\"flex-row align-items-center shadow-sm h-100\"],[3,\"did-insert\",[[23,0,[\"colorProject\"]]]],[3,\"did-update\",[[23,0,[\"colorProject\"]],[23,2,[\"color\"]]]]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[[12,\"class\",[29,[\"text-center \",[23,2,[\"textColor\"]]]]]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"stretched-link\"]],[[\"@route\",\"@model\"],[\"project\",[23,2,[\"id\"]]]]],[0,\"\\n\\n    \"],[7,\"h6\",true],[10,\"class\",\"mb-0\"],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n    \"],[1,[28,\"if\",[[23,2,[\"alias\"]],[28,\"concat\",[\"(\",[23,2,[\"alias\"]],\")\"],null]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"dropdown position-absolute r-0 t-0 z-index-2\"],[8],[0,\"\\n        \"],[7,\"button\",true],[11,\"class\",[29,[\"btn btn-link \",[23,2,[\"textColor\"]]]]],[11,\"id\",[29,[[23,0,[\"elementId\"]],\"-dropdown\"]]],[10,\"data-toggle\",\"dropdown\"],[10,\"aria-haspopup\",\"true\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"fas fa-ellipsis-h\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"dropdown-menu dropdown-menu-right\"],[11,\"aria-labelledby\",[29,[[23,0,[\"elementId\"]],\"-dropdown\"]]],[8],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"dropdown-item\"]],[[\"@route\",\"@model\"],[\"leaderless.edit\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"Adicionar líder\"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/leaderless/project/template.hbs"
    }
  });
  _exports.default = _default;
});