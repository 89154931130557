define("gestor-projetos/pods/leaderless/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sEXT051l",
    "block": "{\"symbols\":[],\"statements\":[[5,\"leaderless/form\",[],[[\"@project\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/leaderless/edit/template.hbs"
    }
  });
  _exports.default = _default;
});