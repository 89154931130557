define("gestor-projetos/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie", "gestor-projetos/config/environment"], function (_exports, _cookie, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class AplicationSessionStore extends _cookie.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "sameSite", 'Strict');
      _defineProperty(this, "cookieDomain", _environment.default.domain);
    }
  }
  _exports.default = AplicationSessionStore;
});