define("gestor-projetos/serializers/task-column", ["exports", "gestor-projetos/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TaskColumnSerializer extends _application.default {
    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      let modelName = primaryModelClass.modelName;
      let camelizeKey = Ember.String.camelize(modelName);
      let dasherizeKey = Ember.String.dasherize(modelName);
      id = payload[camelizeKey || dasherizeKey || modelName].id;
      if (store.hasRecordForId(modelName, id)) {
        let record = store.peekRecord(modelName, id);
        store.unloadRecord(record);
      }
      return super.normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType);
    }
  }
  _exports.default = TaskColumnSerializer;
});