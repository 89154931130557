define("gestor-projetos/nela-dataloader/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/nela-dataloader.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-dataloader.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/nela-paging.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/nela-paging.hbs should pass TemplateLint.\n\naddon/templates/components/nela-paging.hbs\n  2:11  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n  8:82  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n  6:49  error  Ambiguous path \'hideEmpty\' is not allowed. Use \'@hideEmpty\' if it is a named argument or \'this.hideEmpty\' if it is a property on \'this\'. If it is a helper or component that has no arguments, you must either convert it to an angle bracket invocation or manually add it to the \'no-implicit-this\' rule configuration, e.g. \'no-implicit-this\': { allow: [\'hideEmpty\'] }.  no-implicit-this\n  7:55  error  Ambiguous path \'hideLoading\' is not allowed. Use \'@hideLoading\' if it is a named argument or \'this.hideLoading\' if it is a property on \'this\'. If it is a helper or component that has no arguments, you must either convert it to an angle bracket invocation or manually add it to the \'no-implicit-this\' rule configuration, e.g. \'no-implicit-this\': { allow: [\'hideLoading\'] }.  no-implicit-this\n  8:63  error  Ambiguous path \'hideLoadMore\' is not allowed. Use \'@hideLoadMore\' if it is a named argument or \'this.hideLoadMore\' if it is a property on \'this\'. If it is a helper or component that has no arguments, you must either convert it to an angle bracket invocation or manually add it to the \'no-implicit-this\' rule configuration, e.g. \'no-implicit-this\': { allow: [\'hideLoadMore\'] }.  no-implicit-this\n');
  });
  QUnit.test('addon/templates/components/nela-paging/btn-load-more.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/nela-paging/btn-load-more.hbs should pass TemplateLint.\n\naddon/templates/components/nela-paging/btn-load-more.hbs\n  4:7  error  Ambiguous path \'text\' is not allowed. Use \'@text\' if it is a named argument or \'this.text\' if it is a property on \'this\'. If it is a helper or component that has no arguments, you must either convert it to an angle bracket invocation or manually add it to the \'no-implicit-this\' rule configuration, e.g. \'no-implicit-this\': { allow: [\'text\'] }.  no-implicit-this\n');
  });
  QUnit.test('addon/templates/components/nela-paging/empty.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-paging/empty.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/nela-paging/loading.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-paging/loading.hbs should pass TemplateLint.\n\n');
  });
});