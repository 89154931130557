define("gestor-projetos/pods/components/input-mentions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "c9xbGLdA",
    "block": "{\"symbols\":[\"user\",\"Avatar\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-mention__stylist\"],[8],[1,[28,\"html\",[[23,0,[\"mentionTextStylized\"]]],null],false],[9],[0,\"\\n\\n\"],[14,3],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"creatingMention\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"input-mention__list list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"usersToSelect\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"class\",[28,\"if\",[[23,1,[\"selected\"]],\"selected\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"selectUser\",[23,1,[]]],null]],[10,\"role\",\"button\"],[8],[0,\"\\n        \"],[5,\"ui-avatar\",[],[[\"@user\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n          \"],[6,[23,2,[\"Img\"]],[[12,\"class\",\"input-mention__list-avatar\"],[12,\"alt\",[23,1,[\"fullName\"]]]],[[],[]]],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n        \"],[1,[23,1,[\"fullName\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"selected\"],[10,\"role\",\"button\"],[8],[0,\"\\n        Nenhum usuário encontrado\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/components/input-mentions/template.hbs"
    }
  });
  _exports.default = _default;
});