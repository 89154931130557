define("gestor-projetos/pods/components/work-record/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'button',
    classNames: ['btn', 'btn-sm', 'btn-secondary', 'btn-block', 'text-left'],
    // Services
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Lifecycle hooks
    didInsertElement() {
      this._super(...arguments);
      this.element.onclick = () => {
        this.createWorkRecord.perform();
      };
    },
    // Tasks
    createWorkRecord: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          dismiss,
          value
        } = yield this.swal.fire({
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Criar',
          focusConfirm: false,
          html: `<input id="work-record-time" type="time" class="swal2-input hide-spin-button">
          <textarea id="work-record-comment" class="swal2-textarea" placeholder="Comentário">`,
          showCancelButton: true,
          title: 'Novo registro de trabalho',
          preConfirm: () => {
            let time = document.getElementById('work-record-time').value;
            let comment = document.getElementById('work-record-comment').value;
            return {
              comment,
              time
            };
          }
        });
        if (dismiss) {
          return;
        }
        if (!value.time) {
          return this.swal.warning('Informe um tempo válido');
        }
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        let workRecord = this.store.createRecord('work-record', {
          user,
          comment: value.comment,
          date: (0, _moment.default)().toDate(),
          readableTime: value.time,
          running: false,
          task: this.task,
          type: 'manual'
        });
        if (value && value.time) {
          yield workRecord.save();
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});