define("gestor-projetos/pods/project/index/settings/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Single line computed properties
    project: Ember.computed.alias('model'),
    init() {
      this._super(...arguments);
      this.permissionHints = {
        public: 'Todos os usuários serão membros do projeto público.',
        private: 'Os membros do projeto privado deverão ser informados no campo Membros do projeto.',
        confidential: 'Os membros do projeto confidencial deverão ser informados no campo Membros do projeto. Este projeto não será exibido, no perfil dos membros, no Voors.'
      };
    },
    // Multiline computed properties
    planningOptions: Ember.A([{
      label: 'Story points',
      value: 'points'
    }, {
      label: 'Horas',
      value: 'time'
    }]),
    planningSelected: Ember.computed('planningOptions', 'project.planningType', function () {
      return this.planningOptions.findBy('value', this.project.planningType);
    }),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      try {
        if (!this.project.usersAdmin.length) {
          return this.swal.warning('O projeto deve ter pelo menos um líder.');
        }
        yield this.project.validate();
        if (this.project.validations.isInvalid) {
          return this.swal.validation(this.project.validations);
        }
        yield this.project.save();
        this.project.customFields.forEach(field => field.save());
        this.toast.success('Configurações salvas');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      permissionsInfo() {
        this.swal.fire({
          title: 'Permissões de projetos',
          html: `
          <div class="text-left py-3">
            <p>
              <b>Projeto público:</b> ${this.permissionHints.public}
            </p>
            <p>
              <b>Projeto privado:</b> ${this.permissionHints.private}
            </p>
            <p class="mb-0">
              <b>Projeto confidencial:</b> ${this.permissionHints.confidential}
            </p>
          </div>
        `
        });
      },
      closeModal() {
        this.transitionToRoute('project.index');
      },
      createField() {
        let field = this.store.createRecord('project-custom-field', {
          type: 'text'
        });
        this.project.customFields.pushObject(field);
      },
      setConfidential() {
        Ember.setProperties(this.project, {
          confidential: true,
          public: false
        });
      },
      setPrivate() {
        Ember.setProperties(this.project, {
          confidential: false,
          public: false
        });
      },
      setPublic() {
        if (this.project.usersInvited.length) {
          this.swal.confirm('Ao alterar o projeto para público os membros serão removidos, deseja continuar?').then(result => {
            if (!result.value) {
              return;
            }
            Ember.setProperties(this.project, {
              confidential: false,
              public: true
            });
            this.project.usersInvited.clear();
          });
        } else {
          Ember.setProperties(this.project, {
            confidential: false,
            public: true
          });
        }
      },
      toggleColumnSettings() {
        this.toggleProperty('project.invitedCanSeeColumns');
        if (!this.project.invitedCanSeeColumns) {
          Ember.setProperties(this.project, {
            invitedCanSeeTasks: false,
            membersCanMove: false
          });
        }
      },
      toggleTaskSettings() {
        this.toggleProperty('project.invitedCanSeeTasks');
        if (!this.project.invitedCanSeeTasks) {
          Ember.set(this, 'project.membersCanMove', false);
        }
      }
    }
  });
  _exports.default = _default;
});