define("gestor-projetos/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nPzMr4NO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"isReady\"]]],null,{\"statements\":[[0,\"  \"],[5,\"application/navbar\",[],[[\"@searchTerm\"],[[23,0,[\"searchTerm\"]]]]],[0,\"\\n  \"],[5,\"application/sidebar\",[],[[],[]]],[0,\"\\n  \"],[5,\"ui-application\",[[12,\"class\",\"ui-application--margin-none ml-4 pl-2\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"modal-container\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/application/template.hbs"
    }
  });
  _exports.default = _default;
});