define("gestor-projetos/components/ui-textarea", ["exports", "ui-nela/components/ui-textarea"], function (_exports, _uiTextarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiTextarea.default;
    }
  });
});