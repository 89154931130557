define("gestor-projetos/pods/project/index/archived/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    // Default values
    skip: 15,
    // Single line computed properties
    project: Ember.computed.alias('model.project'),
    tasks: Ember.computed.filterBy('model.tasks', 'archived', true),
    // Multiline computed properties
    viewMore: Ember.computed('tasks.[]', 'tasksQnt', function () {
      if (!this.tasks.length) {
        return false;
      }
      return this.tasks.length < this.tasksQnt;
    }),
    // Tasks
    loadTasks: (0, _emberConcurrency.task)(function* () {
      try {
        const url = `projects/${this.project.id}/tasks`;
        let tasks = yield this.store.loadRecords('task', url, {
          archived: 1,
          skip: this.skip,
          limit: 15
        });
        this.tasks.addObjects(tasks);
        Ember.setProperties(this, {
          skip: this.skip + 15,
          tasksQnt: tasks.meta.total
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Custom methods
    handleScroll(element) {
      element.onscroll = () => {
        let scrollBottom = element.offsetHeight + element.scrollTop;
        if (this.viewMore && scrollBottom > element.scrollHeight - 300) {
          this.loadTasks.perform();
        }
      };
    },
    // Actions
    actions: {
      closeModal() {
        this.transitionToRoute('project.index');
      }
    }
  });
  _exports.default = _default;
});