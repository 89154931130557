define("gestor-projetos/pods/project/index/task/edit/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    createComment: false,
    text: '',
    // Single line computed properties
    task: Ember.computed.alias('model'),
    mentions: Ember.A(),
    project: Ember.computed.alias('task.project'),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      if (!this.text) {
        return this.swal.warning('O comentário não pode ser vazio');
      }
      try {
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.sessiont.user.id);
        }
        let comment = this.store.createRecord('task-comment', {
          user,
          company: user.company,
          mentions: this.mentions,
          projec: this.project,
          task: this.task,
          text: this.text
        });
        yield comment.save();
        this.task.reload();
        this.toast.success('Comentário adicionado');
        this.send('clearComment');
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      clearComment() {
        Ember.setProperties(this, {
          createComment: false,
          mentions: Ember.A(),
          text: ''
        });
      },
      toogleCreateComment() {
        this.toggleProperty('createComment');
        setTimeout(() => {
          if (this.createComment) {
            const element = document.getElementById(`input-comment-task${this.project.get('id')}`);
            if (element) {
              element.focus();
            }
          }
        }, 200);
      }
    }
  });
  _exports.default = _default;
});