define("gestor-projetos/pods/project/index/task/edit/related/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CHynx1DA",
    "block": "{\"symbols\":[\"Card\",\"@task\"],\"statements\":[[5,\"ui-card\",[[12,\"class\",\"shadow-sm\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[[12,\"class\",\"d-flex align-items-center text-truncate\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"ui-avatar\",[[12,\"class\",\"avatar-4 flex-shrink-0 mr-2\"],[12,\"alt\",[23,2,[\"userResponsible\",\"fullName\"]]]],[[\"@src\"],[[23,2,[\"userResponsible\",\"avatar\"]]]]],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-fill text-truncate\"],[8],[0,\"\\n      \"],[7,\"h6\",true],[10,\"class\",\"mb-0 font-weight-bold text-truncate\"],[8],[1,[23,2,[\"title\"]],false],[9],[0,\"\\n      \"],[7,\"small\",true],[8],[1,[23,2,[\"project\",\"name\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[5,\"link-to\",[[12,\"class\",\"stretched-link\"],[12,\"target\",\"_blank\"]],[[\"@route\",\"@models\"],[\"project.index.task.edit.index\",[28,\"array\",[[23,2,[\"project\",\"id\"]],[23,2,[\"id\"]]],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/task/edit/related/item/template.hbs"
    }
  });
  _exports.default = _default;
});