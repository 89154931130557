define("gestor-projetos/pods/task/attachments/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TaskAttachmentsRoute extends Ember.Route {
    model() {
      return this.modelFor('task');
    }
  }
  _exports.default = TaskAttachmentsRoute;
});