define("gestor-projetos/mixins/sortable-list", ["exports", "sortablejs"], function (_exports, _sortablejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    makeSortable(options) {
      this.setProperties(options);
      var self = this;
      var el = this.$(this.sortableContainer)[0];
      _sortablejs.default.create(el, {
        group: true,
        animation: 150,
        handle: '.checklist-drag-handle',
        ghostClass: 'checklist-ghost',
        chosenClass: 'checklist-dragging',
        setData: (dataTransfer, dragEl) => {
          var childId = $(dragEl).data('id');
          dataTransfer.setData(`kb/${self.get('childModel')}`, childId);
        },
        onAdd() {
          self.beginPropertyChanges();
          self.set('promisesForAdding', self.updateChildrenPositions());
          Ember.RSVP.all(self.get('promisesForAdding')).then();
        },
        onUpdate() {
          Ember.RSVP.all(self.updateChildrenPositions()).then();
        }
      });
    },
    updateChildrenPositions() {
      let childModel = this.childModel;
      let childSelector = this.childSelector;
      let promises = [];
      $(childSelector).each((index, cardEl) => {
        let childId = $(cardEl).data('id');
        promises.push(this.store.find(childModel, childId).then(child => {
          child.set('order', index);
          return child.save();
        }));
      });
      return promises;
    }
  });
  _exports.default = _default;
});