define("gestor-projetos/models/checklist-item", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    endDate: [(0, _emberCpValidations.validator)('date', {
      precision: 'day',
      allowBlank: true,
      onOrAfter: Ember.computed.reads('model.checklist.project.initialDate'),
      onOrBefore: Ember.computed.reads('model.checklist.project.conclusionDate'),
      message: 'A data deve estar entre a data de início e a data de término do projeto'
    }), (0, _emberCpValidations.validator)('date', {
      precision: 'day',
      allowBlank: true,
      onOrAfter: Ember.computed.reads('model.checklist.task.initialDate'),
      onOrBefore: Ember.computed.reads('model.checklist.task.conclusionDate'),
      message: 'A data deve estar entre a data de início e a data de término da tarefa'
    })]
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    done: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    endDate: (0, _model.attr)('date'),
    mentions: (0, _model.attr)('raw'),
    order: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    // Associations
    checklist: (0, _model.belongsTo)('checklist')
  });
  _exports.default = _default;
});