define("gestor-projetos/locales/es/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    /* Configuração da bandeira da lĩngua */
    "locale.flag": "/images/es_flag.png"
  };
  _exports.default = _default;
});