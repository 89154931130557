define("gestor-projetos/pods/project/index/task/edit/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    model() {
      return this.modelFor('project.index.task.edit');
    },
    async afterModel(model) {
      const project = this.modelFor('project');
      const columnId = model.taskColumn.get('id');
      const column = await this.store.findRecord('taskColumn', columnId);
      const projectsIAdmin = this.permission.user.projectUsersAdmins.map(p => +p.id);
      const columnResponsibleUsers = column.responsibleUsers.content.map(user => user.get('id'));
      const isProjectAdmin = projectsIAdmin.includes(+model.project.get('id'));
      const isColumnResponsible = columnResponsibleUsers.includes(this.permission.user.id);
      const isTaskResponsible = model.userResponsible.get('id') === this.permission.user.id;
      const isTaskCreator = model.userCreate.get('id') === this.permission.user.id;
      const allowed = isTaskCreator || isTaskResponsible || isColumnResponsible || isProjectAdmin || project.invitedCanSeeTasks && !column.restrictedToUsers;
      if (!allowed) {
        this.swal.warning('Você não tem acesso a essa tarefa');
        return this.router.transitionTo('project.index');
      }
    }
  });
  _exports.default = _default;
});