define("gestor-projetos/pods/project/index/task/form/custom-field/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['form-group'],
    // Services
    store: Ember.inject.service(),
    // Default values
    editField: false,
    // Single line computed properties
    isCurrency: Ember.computed.equal('projectCustomField.type', 'currency'),
    isDate: Ember.computed.equal('projectCustomField.type', 'date'),
    isNumber: Ember.computed.equal('projectCustomField.type', 'number'),
    isText: Ember.computed.equal('projectCustomField.type', 'text'),
    isHour: Ember.computed.equal('projectCustomField.type', 'time'),
    // Multiline computed properties
    customField: Ember.computed('projectCustomField.id', 'task.customFields.@each.id', function () {
      if (!this.task.customFields) {
        return {};
      }
      if (!Array.isArray(this.task.customFields)) {
        return JSON.parse(this.task.customFields).filter(customField => customField.id === this.projectCustomField.id);
      }
      return this.task.customFields.find(field => field.id == this.projectCustomField.id);
    }),
    customDate: Ember.computed('customField.value', 'isDate', {
      get() {
        if (!this.customField.value) {
          return;
        }
        return (0, _moment.default)(this.customField.value).toDate();
      },
      set(key, value) {
        Ember.set(this, 'customField.value', value);
        return value;
      }
    }),
    showInputField: Ember.computed('allowEdit', 'editField', 'task.isNew', function () {
      return this.allowEdit && (this.editField || this.task.isNew);
    }),
    // Lifecycle hooks
    didInsertElement() {
      this._super(...arguments);
      this._initField.perform();
    },
    // Tasks
    _initField: (0, _emberConcurrency.task)(function* () {
      try {
        if (!this.task.customFields) {
          Ember.set(this, 'task.customFields', Ember.A());
        }
        let projectField = this.store.peekRecord('project-custom-field', this.projectCustomField.id);
        if (!projectField) {
          projectField = yield this.store.findRecord('project-custom-field', this.projectCustomField.id);
        }
        const hasCustomField = !!this.task.customFields.find(field => field.id == this.projectCustomField.id);
        if (!hasCustomField) {
          const newCustomField = {
            id: projectField.id,
            name: projectField.name,
            type: projectField.type,
            value: null
          };
          this.task.customFields.addObject(newCustomField);
          if (!this.task.isNew) {
            yield this.task.save();
          }
        }
      } catch (e) {
        Ember.debug(`<Project::Index::Task::CustomField> :: _initField :: ${e}`);
      }
    }).enqueue(),
    actions: {
      cancelFieldEdit() {
        this.task.reload();
        Ember.set(this, 'editField', false);
      },
      handleSave() {
        Ember.set(this, 'editField', false);
        if (this.saveTask) {
          this.saveTask();
        }
      }
    }
  });
  _exports.default = _default;
});