define("gestor-projetos/pods/project/index/milestone/burndown/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    ajax: Ember.inject.service(),
    // Lifecycle hooks
    model(_ref) {
      let {
        milestone_id
      } = _ref;
      return Ember.RSVP.hash({
        milestone: this.store.findRecord('milestone', milestone_id),
        burndown: this.ajax.request(`milestones/${milestone_id}/burndown`)
      });
    }
  });
  _exports.default = _default;
});