define("gestor-projetos/pods/application/super-user-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V7x2SIHA",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[5,\"ui-modal\",[],[[\"@open\"],[[23,0,[\"openModal\"]]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"Login\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n          \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[0,\"Email ou CPF do usuário\"],[9],[0,\"\\n          \"],[5,\"input\",[[12,\"class\",\"form-control\"]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[23,0,[\"userIdentifier\"]],[28,\"perform\",[[23,0,[\"superUserLogin\"]]],null]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary full-width\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"superUserLogin\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n          Logar\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/application/super-user-login/template.hbs"
    }
  });
  _exports.default = _default;
});