define("gestor-projetos/models/company-configuration", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    company: (0, _model.belongsTo)('company'),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    generalOrSpecific: (0, _model.attr)('number'),
    permitChat: (0, _model.attr)('boolean'),
    permitAgenda: (0, _model.attr)('boolean'),
    permitNews: (0, _model.attr)('boolean'),
    permitChart: (0, _model.attr)('boolean'),
    permitSurvey: (0, _model.attr)('boolean'),
    permitMural: (0, _model.attr)('boolean'),
    permitScale: (0, _model.attr)('boolean'),
    permitAd: (0, _model.attr)('boolean'),
    permitNotify: (0, _model.attr)('boolean'),
    permitSuggestionBox: (0, _model.attr)('boolean'),
    permitCompanyPhotos: (0, _model.attr)('boolean'),
    permitUserPhotos: (0, _model.attr)('boolean'),
    permitBiblioteca: (0, _model.attr)('boolean'),
    permitReceipt: (0, _model.attr)('boolean'),
    permitRend: (0, _model.attr)('boolean'),
    permitCartaoPonto: (0, _model.attr)('boolean'),
    permitMessageRh: (0, _model.attr)('boolean'),
    permitDocument: (0, _model.attr)('boolean'),
    permitCarona: (0, _model.attr)('boolean'),
    permitContactList: (0, _model.attr)('boolean'),
    permitEvent: (0, _model.attr)('boolean'),
    permitTraining: (0, _model.attr)('boolean'),
    permitAnnouncement: (0, _model.attr)('boolean'),
    permitProjectsAndTasks: (0, _model.attr)('boolean'),
    permitPolicy: (0, _model.attr)('boolean'),
    permitBenefit: (0, _model.attr)('boolean'),
    folhaLocalURL: (0, _model.attr)('string'),
    userApproval: (0, _model.attr)('boolean'),
    picturesDashboard: (0, _model.attr)('boolean'),
    permitComercialPolitics: (0, _model.attr)('boolean'),
    subscription: (0, _model.attr)('raw'),
    thumbLogo: (0, _model.attr)('string'),
    permitDecisionMaking: (0, _model.attr)('boolean'),
    permitMeetingMinute: (0, _model.attr)('boolean'),
    featureFlags: (0, _model.attr)('raw'),
    showPortalColab: Ember.computed('hasPortalColabModule', 'jobContract', 'permitMessageRh', 'permitReceipt', function () {
      return this.permitReceipt | this.permitMessageRh && this.hasPortalColabModule;
    }),
    hasProjectManagerModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PROJECT-MANAGER');
    }),
    hasTEDModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('TED');
    }),
    hasTrainingModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('TRAINING');
    }),
    hasPortalColabModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PORTAL_COLAB');
    }),
    hasPortalGestorModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PORTAL_GESTOR');
    }),
    hasAVDModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('AVD');
    }),
    hasPesquisaModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PESQUISA');
    }),
    accountIsActive: Ember.computed('subscription.active', function () {
      return this.get('subscription.active') || false;
    })
  });
  _exports.default = _default;
});