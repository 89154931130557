define("gestor-projetos/utils/contrast-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = contrastColor;
  function contrastColor(hex) {
    if (hex) {
      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        return 'text-body';
      }
      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);
      let color = r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'text-dark' : 'text-white';
      return color;
    } else {
      return 'text-body';
    }
  }
});