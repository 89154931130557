define("gestor-projetos/models/task", ["exports", "@ember-data/model", "ember-cp-validations", "moment"], function (_exports, _model, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'A tarefa deve ter um título'
    }),
    initialDate: (0, _emberCpValidations.validator)('date', {
      precision: 'day',
      allowBlank: true,
      onOrAfter: Ember.computed.reads('model.project.initialDate'),
      onOrBefore: Ember.computed.reads('model.project.conclusionDate'),
      message: 'A data inicial deve estar entre a data de início e a data de término do projeto'
    }),
    conclusionDate: [(0, _emberCpValidations.validator)('date', {
      precision: 'day',
      allowBlank: true,
      onOrAfter: Ember.computed.reads('model.initialDate'),
      message: 'A data de término deve ser igual ou posterior à data de início'
    }), (0, _emberCpValidations.validator)('date', {
      precision: 'day',
      allowBlank: true,
      onOrAfter: Ember.computed.reads('model.project.initialDate'),
      onOrBefore: Ember.computed.reads('model.project.conclusionDate'),
      message: 'A data de término deve estar entre a data de início e a data de término do projeto'
    })]
  });
  let TaskModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('raw'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('date'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('raw'), _dec10 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec13 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('date'), _dec19 = (0, _model.belongsTo)('task', {
    inverse: null
  }), _dec20 = (0, _model.belongsTo)('task', {
    inverse: 'generatedTasks'
  }), _dec21 = (0, _model.belongsTo)('milestone'), _dec22 = (0, _model.belongsTo)('project', {
    inverse: 'tasks'
  }), _dec23 = (0, _model.belongsTo)('task-column'), _dec24 = (0, _model.belongsTo)('user'), _dec25 = (0, _model.belongsTo)('user'), _dec26 = (0, _model.hasMany)('task-attachment'), _dec27 = (0, _model.hasMany)('badge', {
    inverse: null
  }), _dec28 = (0, _model.hasMany)('checklist'), _dec29 = (0, _model.hasMany)('task-comment'), _dec30 = (0, _model.hasMany)('task-feedback', {
    inverse: 'task'
  }), _dec31 = (0, _model.hasMany)('user'), _dec32 = (0, _model.hasMany)('task', {
    inverse: 'copyOf'
  }), _dec33 = (0, _model.hasMany)('task-history'), _dec34 = (0, _model.hasMany)('task-reminder'), _dec35 = (0, _model.hasMany)('work-record'), (_class = class TaskModel extends _model.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "alias", _descriptor, this);
      _initializerDefineProperty(this, "archived", _descriptor2, this);
      _initializerDefineProperty(this, "conclusionDate", _descriptor3, this);
      _initializerDefineProperty(this, "consumedPoints", _descriptor4, this);
      _initializerDefineProperty(this, "customFields", _descriptor5, this);
      _initializerDefineProperty(this, "description", _descriptor6, this);
      _initializerDefineProperty(this, "initialDate", _descriptor7, this);
      _initializerDefineProperty(this, "link", _descriptor8, this);
      _initializerDefineProperty(this, "mentions", _descriptor9, this);
      _initializerDefineProperty(this, "order", _descriptor10, this);
      _initializerDefineProperty(this, "priority", _descriptor11, this);
      _initializerDefineProperty(this, "progress", _descriptor12, this);
      _initializerDefineProperty(this, "checklistProgress", _descriptor13, this);
      _initializerDefineProperty(this, "step", _descriptor14, this);
      _initializerDefineProperty(this, "storyPoints", _descriptor15, this);
      _initializerDefineProperty(this, "time", _descriptor16, this);
      _initializerDefineProperty(this, "title", _descriptor17, this);
      _initializerDefineProperty(this, "finalizedAt", _descriptor18, this);
      _initializerDefineProperty(this, "cloneOf", _descriptor19, this);
      _initializerDefineProperty(this, "copyOf", _descriptor20, this);
      _initializerDefineProperty(this, "milestone", _descriptor21, this);
      _initializerDefineProperty(this, "project", _descriptor22, this);
      _initializerDefineProperty(this, "taskColumn", _descriptor23, this);
      _initializerDefineProperty(this, "userCreate", _descriptor24, this);
      _initializerDefineProperty(this, "userResponsible", _descriptor25, this);
      _initializerDefineProperty(this, "attachments", _descriptor26, this);
      _initializerDefineProperty(this, "badges", _descriptor27, this);
      _initializerDefineProperty(this, "checklists", _descriptor28, this);
      _initializerDefineProperty(this, "comments", _descriptor29, this);
      _initializerDefineProperty(this, "feedbacks", _descriptor30, this);
      _initializerDefineProperty(this, "follows", _descriptor31, this);
      _initializerDefineProperty(this, "generatedTasks", _descriptor32, this);
      _initializerDefineProperty(this, "taskHistories", _descriptor33, this);
      _initializerDefineProperty(this, "taskReminders", _descriptor34, this);
      _initializerDefineProperty(this, "workRecords", _descriptor35, this);
    }
    get almostLate() {
      let close = this.taskColumn.get('close');
      return (0, _moment.default)().add(2, 'd').isAfter(this.conclusionDate, 'day') && !(0, _moment.default)().isAfter(this.conclusionDate, 'day') && !close && !this.archived;
    }
    get color() {
      let num = Math.round(Math.random() * Math.pow(10, 7));
      return `#${num.toString(16)}`;
    }
    get consumedTime() {
      let sum = 0;
      let workRecords = this.workRecords;
      if (workRecords.length) {
        workRecords.forEach(workRecord => {
          let time = workRecord.get('time');
          if (!isNaN(time)) {
            sum += time;
          }
        });
      }
      return sum;
    }
    get late() {
      let close = this.taskColumn.get('close');
      return (0, _moment.default)().isAfter(this.conclusionDate, 'day') && !close && !this.archived;
    }
    get pendingFeedback() {
      return this.feedbacks.findBy('status', 'pending');
    }
    get taskProgressWidth() {
      return Ember.String.htmlSafe(`width: ${this.progress}%; opacity: 1;`);
    }
    get titleWithAlias() {
      if (!this.alias) {
        return this.title;
      }
      return `(${this.alias}) ${this.title}`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "alias", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "archived", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "conclusionDate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "consumedPoints", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "customFields", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "link", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "mentions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "order", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "priority", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "progress", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "checklistProgress", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "step", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "storyPoints", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "time", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "finalizedAt", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "cloneOf", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "copyOf", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "milestone", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "taskColumn", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "userCreate", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "userResponsible", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "badges", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "checklists", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "comments", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "feedbacks", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "follows", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "generatedTasks", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "taskHistories", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "taskReminders", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "workRecords", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TaskModel;
});