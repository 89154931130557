define("gestor-projetos/models/project", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data-copyable", "gestor-projetos/utils/contrast-color"], function (_exports, _model, _emberCpValidations, _emberDataCopyable, _contrastColor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'O projeto deve ter um nome'
    }),
    conclusionDate: [(0, _emberCpValidations.validator)('date', {
      precision: 'day',
      allowBlank: true,
      onOrAfter: Ember.computed.reads('model.initialDate'),
      message: 'A data de término deve ser igual ou posterior à data de início'
    }), (0, _emberCpValidations.validator)('no-task-with-posterior-date')],
    usersAdmin: (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'O projeto deve ter pelo menos um líder'
    }),
    alias: (0, _emberCpValidations.validator)('length', {
      max: 20,
      message: 'O campo alias deve conter no máximo 20 caracteres'
    })
  });
  var _default = _model.default.extend(Validations, _emberDataCopyable.default, {
    // Attributes
    active: (0, _model.attr)('boolean'),
    advancedTaskCreation: (0, _model.attr)('boolean'),
    alias: (0, _model.attr)('string'),
    color: (0, _model.attr)('string', {
      defaultValue: '#156FB2'
    }),
    commentWhenLate: (0, _model.attr)('boolean'),
    conclusionDate: (0, _model.attr)('date'),
    confidential: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    filed: (0, _model.attr)('boolean'),
    hasChecklistDate: (0, _model.attr)('boolean'),
    hasNoTasks: (0, _model.attr)('boolean'),
    initialDate: (0, _model.attr)('date'),
    invitedCanCreateTasks: (0, _model.attr)('boolean'),
    invitedCanSeeColumns: (0, _model.attr)('boolean'),
    invitedCanSeeTasks: (0, _model.attr)('boolean'),
    membersCanMove: (0, _model.attr)('boolean'),
    name: (0, _model.attr)('string'),
    planningType: (0, _model.attr)('string'),
    public: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    sendEmailWhenLate: (0, _model.attr)('boolean'),
    // Associations
    cloneOf: (0, _model.belongsTo)('project', {
      inverse: null
    }),
    creator: (0, _model.belongsTo)('user', {
      inverse: 'created'
    }),
    badges: (0, _model.hasMany)('badge', {
      inverse: 'project'
    }),
    customFields: (0, _model.hasMany)('project-custom-field', {
      inverse: 'project'
    }),
    milestones: (0, _model.hasMany)('milestone', {
      inverse: 'project'
    }),
    taskColumns: (0, _model.hasMany)('task-column', {
      inverse: 'project'
    }),
    tasks: (0, _model.hasMany)('task', {
      inverse: 'project'
    }),
    usersAdmin: (0, _model.hasMany)('user', {
      inverse: 'projectUsersAdmins'
    }),
    usersInvited: (0, _model.hasMany)('user', {
      inverse: null
    }),
    defaultChecklists: (0, _model.hasMany)('default-checklist'),
    // Computed properties
    users: Ember.computed.union('usersInvited', 'usersAdmin', 'creator'),
    textColor: Ember.computed('color', function () {
      return (0, _contrastColor.default)(this.color);
    })
  });
  _exports.default = _default;
});