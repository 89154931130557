define("gestor-projetos/pods/home/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.computed.empty('args.project.hasNoTasks'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "planningSelected", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "swal", _descriptor6, this);
      _initializerDefineProperty(this, "toast", _descriptor7, this);
      _defineProperty(this, "planningOptions", Ember.A([{
        label: 'Story points',
        value: 'points'
      }, {
        label: 'Horas',
        value: 'time'
      }]));
      _defineProperty(this, "permissionHints", {
        public: 'Todos os usuários serão membros do projeto público.',
        private: 'Os membros do projeto privado deverão ser informados no campo Membros do projeto.',
        confidential: 'Os membros do projeto confidencial deverão ser informados no campo Membros do projeto. Este projeto não será exibido, no perfil dos membros, no Voors Social.'
      });
      _initializerDefineProperty(this, "hasNoTasks", _descriptor8, this);
    }
    *save() {
      let {
        afterSave,
        project
      } = this.args;
      try {
        if (project.validations.isInvalid) {
          return this.swal.validation(project.validations);
        }
        yield project.save();
        this.toast.success('Projeto salvo');
        if (afterSave) {
          afterSave(project);
        }
        this.router.transitionTo('home');
      } catch (e) {
        Ember.debug(e);
      }
    }
    aliasInfo() {
      this.swal.fire({
        title: 'Alias do projeto',
        html: `
        <div class="text-left">
          Este campo  serve para identificar seu projeto com uma abreviação, sigla ou apelido.
          Caso seu projeto possua tarefas não é permitida a edição desta informação.
        </div>
      `
      });
    }
    permissionsInfo() {
      this.swal.fire({
        title: 'Permissões de projetos',
        html: `
        <div class="text-left py-3">
          <p>
            <b>Projeto público:</b> ${this.permissionHints.public}
          </p>
          <p>
            <b>Projeto privado:</b> ${this.permissionHints.private}
          </p>
          <p class="mb-0">
            <b>Projeto confidencial:</b> ${this.permissionHints.confidential}
          </p>
        </div>
      `
      });
    }
    closeModal() {
      this.router.transitionTo('home');
    }
    selectType(selected) {
      this.planningSelected = selected;
      this.args.project.planningType = selected.value;
    }
    setConfidential() {
      this.args.project.confidential = true;
      this.args.project.public = false;
    }
    setPrivate() {
      this.args.project.confidential = false;
      this.args.project.public = false;
    }
    setPublic() {
      let setPublic = () => {
        this.args.project.confidential = false;
        this.args.project.public = true;
      };
      if (this.args.project.usersInvited.length) {
        this.swal.confirm('Ao alterar o projeto para público os membros serão removidos, deseja continuar?').then(result => {
          if (!result.value) {
            return;
          }
          setPublic();
          this.args.project.usersInvited.clear();
        });
      } else {
        setPublic();
      }
    }
    setPlanningType() {
      if (this.args.project) {
        if (this.args.project.planningType) {
          this.selectType(this.planningOptions.find(po => po.value === this.args.project.planningType));
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "planningSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasNoTasks", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "aliasInfo", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "aliasInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "permissionsInfo", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "permissionsInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectType", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setConfidential", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setConfidential"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPrivate", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setPrivate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPublic", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setPublic"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPlanningType", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setPlanningType"), _class.prototype)), _class));
  _exports.default = FormComponent;
});