define("gestor-projetos/services/socket", ["exports", "gestor-projetos/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      version
    }
  } = _environment.default;
  var _default = Ember.Service.extend({
    // Services
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    contactService: Ember.inject.service(),
    // Default values
    areListenersInitialized: false,
    hasAuthenticateCallback: null,
    isSocketAuthenticated: false,
    /**
     * Função para autenticar socket na API
     */
    authenticateSocket() {
      return this.post('/socketlogin', {
        origin: 'projetos-web',
        version: version.split('+')[0]
      }).then(response => {
        Ember.debug(`Socket login: ${response}`);
        Ember.set(this, 'isSocketAuthenticated', true);
        if (!this.areListenersInitialized) {
          this.initSocketListeners();
          Ember.set(this, 'areListenersInitialized', true);
        }
        return;
      });
    },
    /**
     * Inicializa listeners do socket.
     */
    initSocketListeners() {
      this.on('needsAuthentication', data => {
        Ember.debug(`*** Pediu autenticação *** : ${data.body}`);
        this.authenticateSocket();
      });
      this.on('disconnect', () => {
        Ember.debug('Socket desconectado');
      });
    },
    /**
     * Encapsula requisição socket.
     * @param  {[Object]}   options Opções da requisição
     * @return {[Promise]}  Retorna uma Promise com o resultado da requisição
     */
    request(options) {
      return new Ember.RSVP.Promise(resolve => {
        io.socket.request({
          method: options.method,
          url: options.url,
          data: options.data,
          headers: {
            authorization: `Bearer ${this.session.token}`
          }
        }, response => {
          resolve(response);
        });
      });
    },
    /**
     * Syntax sugar para requisição POST
     * @param  {[String]} url Endereço da requisição
     * @param  {[Object]} data Dados a enviar
     * @return {[Promise]}  Retorna uma Promise com o resultado da requisição
     */

    post(url, data) {
      return this.request({
        url,
        data,
        method: 'POST'
      });
    },
    /**
     * Syntax sugar para requisição GET
     * @param  {[String]} url Endereço da requisição
     * @return {[Promise]}  Retorna uma Promise com o resultado da requisição
     */
    get(url) {
      return this.request({
        url,
        method: 'GET'
      });
    },
    /**
     * Encapsula listener do socket
     * @param  {[String]}   event     Evento a ser tratado
     * @param  {Function}   callback  Callback
     * @return {[Promise]}  Retorna uma Promise com o resultado da requisição
     */
    on(event, callback) {
      io.socket.on(event, callback);
    }
  });
  _exports.default = _default;
});