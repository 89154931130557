define("gestor-projetos/pods/project/index/task/form/checklist/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['mb-3'],
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Single line computed properties
    project: Ember.computed.alias('task.project'),
    // Tasks
    removeChecklist: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.checklist.isNew) {
          return this.checklist.rollbackAttributes();
        }
        let {
          value
        } = yield this.swal.confirm('Confirmar remoção deste checklist?');
        if (!value) {
          return;
        }
        yield this.ajax.put(`checklist/${this.checklist.id}/softDeleteChecklist`);
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        let taskHistory = this.store.createRecord('task-history', {
          user,
          date: (0, _moment.default)().toDate(),
          task: this.task,
          checklist: this.checklist,
          type: 'deleted-checklist'
        });
        yield taskHistory.save();
        this.toast.success('Checklist removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      let isNew = this.checklist.isNew;
      try {
        yield (0, _emberConcurrency.timeout)(800);
        yield this.checklist.save();
        if (isNew) {
          let user = this.store.peekRecord('user', this.session.user.id);
          if (!user) {
            user = yield this.store.findRecord('user', this.session.user.id);
          }
          let history = this.store.createRecord('task-history', {
            user,
            checklist: this.checklist,
            date: (0, _moment.default)().toDate(),
            task: this.task,
            type: 'created-checklist'
          });
          yield history.save();
        }
      } catch (e) {
        this.checklist.rollbackAttributes();
        this.swal.catch(e);
      }
    }).restartable(),
    // Custom methods
    updateProgress(element) {
      element.style.width = `${this.checklist.progress}%`;
    },
    checklistFocus(element) {
      if (!this.checklist.isNew) {
        return;
      }
      element.focus();
    },
    actions: {
      createItem() {
        const verifyChecklistTitleEmpety = this.checklist.checklistItems.find(item => !item.title && item.active);
        if (verifyChecklistTitleEmpety) {
          return this.swal.warning(`Informe um título para o item do checklist.`);
        }
        this.store.createRecord('checklist-item', {
          checklist: this.checklist,
          order: this.checklist.checklistItems.length
        });
        this.checklist.checklistItems.addObject();
      },
      removeChecklistItem(checklistItem) {
        this.checklist.checklistItems.removeObject(checklistItem);
      },
      saveOnEnter() {
        this.save.perform();
        this.send('createItem');
      }
    }
  });
  _exports.default = _default;
});