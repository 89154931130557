define("gestor-projetos/pods/home/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XQSky2rr",
    "block": "{\"symbols\":[],\"statements\":[[5,\"home/form\",[],[[\"@project\",\"@afterSave\"],[[23,0,[\"project\"]],[28,\"fn\",[[23,0,[\"afterSave\"]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/home/create/template.hbs"
    }
  });
  _exports.default = _default;
});