define("gestor-projetos/pods/components/task-timer/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'button',
    classNames: ['btn', 'btn-sm', 'btn-secondary', 'btn-block', 'text-left'],
    // Services
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Default values
    time: 0,
    timer: null,
    // Multiline computed properties
    formatedTime: Ember.computed('time', function () {
      return (0, _moment.default)().startOf('day').seconds(this.time).format('H:mm:ss');
    }),
    runningWorkRecord: Ember.computed('task.workRecords.@each.running', function () {
      return this.task.workRecords.findBy('running', true);
    }),
    // Lifecycle hooks
    didReceiveAttrs() {
      if (this.runningWorkRecord) {
        this.startTimer();
      }
    },
    didInsertElement() {
      this._super(...arguments);
      this.element.onclick = () => {
        if (this.runningWorkRecord) {
          this.stopWorkRecord.perform();
        } else {
          this.startWorkRecord.perform();
        }
      };
    },
    willDestroyElement() {
      this.stopTimer();
    },
    // Tasks
    startWorkRecord: (0, _emberConcurrency.task)(function* () {
      try {
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user'.this.session.user.id);
        }
        let workRecord = this.store.createRecord('work-record', {
          user,
          initialDate: (0, _moment.default)().toDate(),
          running: true,
          task: this.task,
          type: 'recorded'
        });
        Ember.set(this, 'runningWorkRecord', workRecord);
        yield workRecord.save();
        this.startTimer();
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    stopWorkRecord: (0, _emberConcurrency.task)(function* () {
      try {
        let workRecord = this.runningWorkRecord;
        let {
          dismiss,
          value
        } = yield this.swal.fire({
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Concluir',
          input: 'textarea',
          showCancelButton: true,
          text: 'Deixe um comentário'
        });
        if (dismiss) {
          return;
        }
        let diff = (0, _moment.default)().diff((0, _moment.default)(this.runningWorkRecord.initialDate), 'seconds');
        let readableTime = (0, _moment.default)().startOf('day').seconds(diff).format('HH:mm');
        Ember.setProperties(this.runningWorkRecord, {
          readableTime,
          comment: value,
          date: (0, _moment.default)().toDate(),
          running: false
        });
        yield workRecord.save();
        this.stopTimer();
      } catch (e) {
        console.error(e);
        this.swal.catch(e);
      }
    }).drop(),
    // Custom methods
    startTimer() {
      this.stopTimer();
      let runningWorkRecord = this.runningWorkRecord;
      let start = (0, _moment.default)(Ember.get(runningWorkRecord, 'initialDate'));
      let now = (0, _moment.default)();
      let diff = now.diff(start, 'seconds');
      Ember.set(this, 'time', diff);
      Ember.set(this, 'timer', this.getTimer());
    },
    stopTimer() {
      clearInterval(this.timer);
      Ember.set(this, 'time', 0);
    },
    getTimer() {
      return setInterval(() => {
        Ember.set(this, 'time', this.time + 1);
      }, 1000);
    }
  });
  _exports.default = _default;
});