define("gestor-projetos/pods/project/index/badges/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    // Lifecycle hooks
    model() {
      const project = this.modelFor('project');
      let projectAdmins = [];
      project.usersAdmin.forEach(user => {
        projectAdmins.push(user.id);
      });
      const allowed = projectAdmins.includes(this.permission.user.id) || this.permission.admin;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
      return project;
    }
  });
  _exports.default = _default;
});