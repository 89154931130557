define("gestor-projetos/models/milestone", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'A milestone deve ter um nome'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    conclusionDate: (0, _model.attr)('date'),
    description: (0, _model.attr)('string'),
    initialDate: (0, _model.attr)('date'),
    progress: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    // Associations
    project: (0, _model.belongsTo)('project', {
      inverse: 'milestones'
    }),
    tasks: (0, _model.hasMany)('task')
  });
  _exports.default = _default;
});