define("gestor-projetos/utils/uppercase-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _upperCaseKeys;
  /**
   * Transforma todas os atributos em letras maiusculas
   */
  function _upperCaseKeys(obj) {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      newobj[key.toUpperCase()] = obj[key];
    }
    return newobj;
  }
});