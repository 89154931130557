define("gestor-projetos/pods/project/index/status/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Single line computed properties
    project: Ember.computed.alias('model.project'),
    status: Ember.computed.alias('model.status'),
    // Custom methods
    updateProgress(element) {
      element.style.width = `${this.status.progress}%`;
    },
    // Actions
    actions: {
      closeModal() {
        this.transitionToRoute('project.index');
      }
    }
  });
  _exports.default = _default;
});