define("gestor-projetos/pods/project/index/archived/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      let project = this.modelFor('project');
      return Ember.RSVP.hash({
        project,
        tasks: this.store.loadRecords('task', `projects/${project.id}/tasks`, {
          archived: 1,
          limit: 15
        })
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      Ember.setProperties(this.controller, {
        skip: 15,
        tasksQnt: model.tasks.meta.total
      });
    }
  });
  _exports.default = _default;
});