define("gestor-projetos/models/checklist", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    title: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    // Associations
    project: (0, _model.belongsTo)('project'),
    task: (0, _model.belongsTo)('task'),
    checklistItems: (0, _model.hasMany)('checklist-item', {
      inverse: 'checklist'
    }),
    // Computed properties
    progress: Ember.computed('checklistItems.@each.done', function () {
      let itens = this.checklistItems.filterBy('isNew', false);
      let itensActive = itens.filter(item => item.active);
      let total = itensActive.length;
      let done = itens.filterBy('done').length;
      let progress = done / total * 100;
      return isNaN(progress) ? 0 : Math.round(progress);
    }),
    progressWidth: Ember.computed('checklistItems.@each.done', function () {
      let itens = this.checklistItems.filterBy('isNew', false);
      let total = itens.get('length');
      let done = itens.filterBy('isNew', false).filterBy('done').get('length');
      let progress = done / total * 100;
      return new Ember.String.htmlSafe(`width: ${progress}%; opacity: 1;`);
    }),
    sortedItems: Ember.computed('checklistItems.@each.order', function () {
      return this.checklistItems.filter(item => item.active).sortBy('order');
    })
  });
  _exports.default = _default;
});