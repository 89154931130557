define("gestor-projetos/pods/project/index/task/edit/related/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "09+7uQNz",
    "block": "{\"symbols\":[\"relatedTask\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"py-3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[23,0,[\"task\",\"copyOf\"]],[23,0,[\"task\",\"cloneOf\"]],[23,0,[\"task\",\"generatedTasks\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,0,[\"task\",\"copyOf\"]]],null,{\"statements\":[[0,\"        \"],[5,\"project/index/task/edit/related/item\",[],[[\"@task\"],[[23,0,[\"task\",\"copyOf\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"task\",\"cloneOf\"]]],null,{\"statements\":[[0,\"        \"],[5,\"project/index/task/edit/related/item\",[],[[\"@task\"],[[23,0,[\"task\",\"cloneOf\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,0,[\"task\",\"generatedTasks\"]]],null,{\"statements\":[[0,\"        \"],[5,\"project/index/task/edit/related/item\",[],[[\"@task\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-12 text-center\"],[8],[0,\"\\n        \"],[7,\"i\",true],[8],[0,\"Sem tarefas relacionadas\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/task/edit/related/template.hbs"
    }
  });
  _exports.default = _default;
});