define("gestor-projetos/pods/project/index/badges/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['list-group-item', 'd-flex', 'align-items-center'],
    // Services
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Lifecycle hooks
    willDestroyElement() {
      this._super(...arguments);
      this.badge.rollbackAttributes();
    },
    // Tasks
    removeBadge: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.badge.isNew) {
          return this.badge.rollbackAttributes();
        }
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão da etiqueta?');
        if (!value) {
          return;
        }
        yield this.badge.destroyRecord();
        this.toast.success('Etiqueta removida');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        yield (0, _emberConcurrency.timeout)(600);
        yield this.badge.save();
        this.project.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable(),
    // Actions
    actions: {
      colorChanged(color) {
        Ember.set(this, 'badge.color', color);
        this.save.perform();
      }
    }
  });
  _exports.default = _default;
});