define("gestor-projetos/pods/project/index/settings/custom-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2fL+JqxV",
    "block": "{\"symbols\":[\"option\",\"@projectCustomField\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex align-items-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-fill\"],[8],[0,\"\\n    \"],[5,\"input\",[[12,\"class\",\"form-control form-control-sm\"]],[[\"@value\",\"@placeholder\"],[[23,2,[\"name\"]],\"Título\"]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-5\"],[8],[0,\"\\n    \"],[5,\"power-select\",[],[[\"@triggerClass\",\"@renderInPlace\",\"@options\",\"@selected\",\"@onChange\"],[\"form-control form-control-sm overflow-hidden\",true,[23,0,[\"fieldOptions\"]],[23,0,[\"typeSelected\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[\"type\"]]],null]],[[\"value\"],[\"value\"]]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"label\"]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-link text-body px-0\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"removeField\"]]],null]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/settings/custom-field/template.hbs"
    }
  });
  _exports.default = _default;
});