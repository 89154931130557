define("gestor-projetos/pods/project/index/default-checklists/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TM9Dejje",
    "block": "{\"symbols\":[],\"statements\":[[5,\"project/index/default-checklists/form\",[],[[\"@checklist\",\"@isNew\"],[[22,\"model\"],false]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/default-checklists/edit/template.hbs"
    }
  });
  _exports.default = _default;
});