define("gestor-projetos/pods/project/index/task/edit/attachments/controller", ["exports", "ember-concurrency-decorators", "moment", "gestor-projetos/config/environment"], function (_exports, _emberConcurrencyDecorators, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProjectIndexTaskEditAttachmentsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('model.project'), _dec6 = Ember.computed.alias('model'), _dec7 = (0, _emberConcurrencyDecorators.dropTask)({
    maxConcurrency: 5
  }), (_class = class ProjectIndexTaskEditAttachmentsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fileQueue", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "project", _descriptor5, this);
      _initializerDefineProperty(this, "task", _descriptor6, this);
    }
    get allowPostAttachment() {
      let userId = String(this.session.user.id);
      let creator = Ember.get(this.task, 'userCreate.id') === userId;
      let responsible = Ember.get(this.task, 'userResponsible.id') === userId;
      let isPublic = Ember.get(this.task, 'project.public');
      return creator || responsible || isPublic;
    }
    flushQueue() {
      const queue = this.fileQueue.find('task-attachments');
      Ember.get(queue, 'files').forEach(file => Ember.set(file, 'queue', null));
      Ember.set(queue, 'files', Ember.A());
    }
    *uploadAttachment(file) {
      const uploadUrl = `${_environment.default.apiUrl.projetos}/taskAttachments/upload`;
      if (file.size > 52428800) {
        this.flushQueue();
        return this.swal.warning('Tamanho máximo do arquivo é 50MB');
      }
      try {
        let response = yield file.upload(uploadUrl, {
          fileKey: 'attachment',
          headers: {
            Authorization: `Bearer ${this.session.token}`
          },
          withCredentials: true
        });
        let {
          name,
          path,
          type,
          url
        } = response.body;
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        let taskAttachment = this.store.createRecord('task-attachment', {
          name,
          path,
          type,
          url,
          user,
          task: this.task
        });
        yield taskAttachment.save();
        this.task.reload();
        let history = this.store.createRecord('task-history', {
          user,
          taskAttachment,
          date: (0, _moment.default)().toDate(),
          task: this.task,
          type: 'created-upload'
        });
        yield history.save();
        this.toast.success('Arquivo enviado');
      } catch (e) {
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileQueue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "task", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uploadAttachment", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "uploadAttachment"), _class.prototype)), _class));
  _exports.default = ProjectIndexTaskEditAttachmentsController;
});