define("gestor-projetos/mixins/dragdrop-card", ["exports", "sortablejs", "jquery"], function (_exports, _sortablejs, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line
  var _default = Ember.Mixin.create({
    modal: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    /**
     * @method
     * Inicializa a biblioteca de drag and drop Sortable.js
     * É chamado no componente em que for utilizada, sendo que recebe um objeto
     * com as opções
     */
    makeSortable(options) {
      this.setProperties(options);
      var self = this;
      var el = this.$(this.sortableContainer)[0];
      _sortablejs.default.create(el, {
        group: this.connected,
        animation: 150,
        handle: '.kb-drag-handle',
        ghostClass: 'kb-ghost',
        chosenClass: 'kb-dragging',
        setData: (dataTransfer, dragEl) => {
          var childId = (0, _jquery.default)(dragEl).data('id');
          dataTransfer.setData(`kb/${self.get('childModel')}`, childId);
        },
        onAdd(evt) {
          self.beginPropertyChanges();
          let childId = (0, _jquery.default)(evt.item).data('id');
          let childModel = Ember.get(self, 'childModel');
          let parentModel = Ember.get(self, 'parentModel');
          let taskColumn = Ember.get(self, parentModel);
          let task = Ember.get(self, 'store').peekRecord(childModel, childId);
          self.beforeMove(task, taskColumn).then(function (value) {
            (0, _jquery.default)(evt.item).attr('data-parent-id', Ember.get(taskColumn, 'id'));
            self.updateChildrenPositions(Ember.get(taskColumn, 'id'), evt).then(tasks => {
              self.endPropertyChanges();
              Ember.get(self, 'store').pushPayload(tasks);
              self.afterMove(task, taskColumn, childModel, value);
            });
          }, function () {
            self.cancelMove(task, evt);
          });
        },
        onUpdate(evt) {
          let parentId = (0, _jquery.default)(evt.item).data('parent-id');
          self.updateChildrenPositions(parentId).then(tasks => {
            Ember.get(self, 'store').pushPayload(tasks);
          });
        }
      });
    },
    beforeMove(task, taskColumn) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let taskCreator = task.get('userCreate.id');
        let copyToProject = taskColumn.get('copyToProject');
        copyToProject.then(project => {
          if (copyToProject.isRejected) throw 'Falha ao carregar a configuração da coluna.';
          if (project && taskCreator) {
            let membersOnTargetProject = project.get('usersInvited').mapBy('id');
            if (!membersOnTargetProject.includes(taskCreator)) {
              this.swal.warning(`Não foi possível mover e copiar a tarefa. O criador não é membro do projeto de destino.`);
              return reject();
            }
          }
          if (taskColumn.get('forcesComment') || task.get('late') && task.get('project.commentWhenLate')) {
            let project = Ember.get(task, 'project');
            let mentions = [];
            this.modal.open('dialogs/imposed-comment', {
              project,
              mentions
            }).then(resolve).catch(reject);
          } else {
            resolve();
          }
        }).catch(e => {
          Ember.debug(e);
          this.swal.warning(`Não foi possível mover e copiar a tarefa. Você não é membro do projeto de destino.`);
          return reject();
        });
      });
    },
    /**
     * @method
     * Percorre todos os cards do board para atualizar a ordem.
     */
    updateChildrenPositions(parentId, evt) {
      let childSelector = this.childSelector;
      let tasks = [];
      (0, _jquery.default)(`${childSelector}[data-parent-id='${parentId}']`).each((index, cardEl) => {
        let childId = (0, _jquery.default)(cardEl).data('id');
        let task = {
          id: childId,
          order: index,
          taskColumn: parentId
        };
        tasks.push(task);
      });
      return this.ajax.post('/tasks/updateOrders', {
        data: {
          tasks
        },
        contentType: 'application/json; charset=UTF-8'
      }).then(returnedTasks => {
        // Remove o objeto da tela pois o mesmo será duplicado pelo observer
        // Não parece uma boa implementação, mas foi a única maneira de resolver
        if (evt) (0, _jquery.default)(evt.item).remove();
        return returnedTasks;
      });
    },
    /**
     * @method
     * Executa ações após movimentação do cartão
     */
    afterMove(task, taskColumn, childModel, comment) {
      let date = moment().toDate();
      let userId = Ember.get(this, 'session.session.content.authenticated.user.id');
      let user = this.store.peekRecord('user', userId);
      this.createTaskHistory(user, task, date, taskColumn);
      this.copyTask(task, taskColumn);
      if (comment) this.createComment(user, task, Ember.get(comment, 'text'), Ember.get(comment, 'mentions'));
    },
    /**
     * @method
     * Cria o histórico do cartão. Chamado quando o cartão é movimentado.
     */
    createTaskHistory(user, task, date, taskColumn) {
      let type = taskColumn.get('close') ? 'finished' : 'moved';
      let taskHistory = this.store.createRecord('taskHistory', {
        user,
        task,
        date,
        taskColumn,
        type
      });
      taskHistory.save();
    },
    /**
     * @method
     * Cria o comentário quando necessário.
     */
    createComment(user, task, text, mentions) {
      let comment = this.store.createRecord('taskComment', {
        user,
        task,
        text,
        mentions
      });
      comment.save();
    },
    copyTask(task, column) {
      if (column.get('allowCopyToProject')) {
        let shouldKeepCreator = Ember.get(column, 'keepTaskCreator');
        let taskId = task.get('id');
        let project = column.get('copyToProject.id');
        let taskColumn = column.get('copyToColumn.id');
        this.ajax.request(`/tasks/${taskId}/clone`, {
          method: 'POST',
          data: {
            project,
            taskColumn,
            meta: {
              shouldKeepCreator,
              shouldCopyChecklists: true
            }
          }
        }).then();
      }
    },
    /**
     * @method
     * Cancela o movimento de um cartão, retirando ele da coluna onde foi movida
     * e recolocando na coluna antiga.
     */
    cancelMove(task, evt) {
      task.rollbackAttributes();
      (0, _jquery.default)(evt.item).remove();
      let index = (0, _jquery.default)(evt.item).data('order');
      if (index == 0) {
        (0, _jquery.default)(evt.from).prepend(evt.item);
        return;
      }
      (0, _jquery.default)(`.kb-column[data-id=${Ember.get(task, 'taskColumn.id')}] .kb-cards .task-card:nth-child(${index})`).after(evt.item);
      return;
    }
  });
  _exports.default = _default;
});