define("gestor-projetos/pods/project/index/milestone/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['list-group-item', 'd-flex', 'align-items-center'],
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Tasks
    clone: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Duplicar milestone?');
        if (!value) {
          return;
        }
        let response = yield this.ajax.request(`milestones/${this.milestone.id}/clone`);
        this.store.pushPayload(response);
        this.toast.success('Milestone duplicada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover milestone?');
        if (!value) {
          return;
        }
        yield this.milestone.destroyRecord();
        this.toast.success('Milestone removida');
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});