define("gestor-projetos/components/modal-container", ["exports", "ember-modal-service/components/modal-container"], function (_exports, _modalContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modalContainer.default;
    }
  });
});