define("gestor-projetos/pods/project/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      let project = this.modelFor('project');
      return Ember.RSVP.hash({
        project,
        taskColumns: this.store.loadRecords('task-column', `projects/${project.id}/taskColumns`)
      });
    },
    afterModel(model) {
      let {
        project,
        taskColumns
      } = model;
      project.on('addColumnViaSocket', taskColumn => {
        taskColumns.addObject(taskColumn);
      });
      project.on('removeColumnViaSocket', taskColumn => {
        taskColumns.removeObject(taskColumn);
      });
    }
  });
  _exports.default = _default;
});