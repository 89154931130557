define("gestor-projetos/models/work-record", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    comment: (0, _model.attr)('string'),
    date: (0, _model.attr)('date'),
    initialDate: (0, _model.attr)('date'),
    readableTime: (0, _model.attr)('string'),
    running: (0, _model.attr)('boolean'),
    time: (0, _model.attr)('number'),
    type: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    task: (0, _model.belongsTo)('task'),
    user: (0, _model.belongsTo)('user'),
    // Computed properties
    hours: Ember.computed('readableTime', function () {
      if (!this.readableTime) {
        return '';
      }
      return this.readableTime.split(':')[0];
    }),
    minutes: Ember.computed('readableTime', function () {
      if (!this.readableTime) {
        return '';
      }
      return this.readableTime.split(':')[1];
    })
  });
  _exports.default = _default;
});