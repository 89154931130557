define("gestor-projetos/services/permission", ["exports", "ember-concurrency-decorators", "gestor-projetos/config/environment"], function (_exports, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PermissionService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed.alias('accessProfile.admin'), (_class = class PermissionService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "socket", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor5, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor6, this);
      _initializerDefineProperty(this, "isLoaded", _descriptor7, this);
      _initializerDefineProperty(this, "admin", _descriptor8, this);
    }
    *load() {
      if (!this.session.isAuthenticated || this.isLoaded) {
        return;
      }
      if (!this.session.user) {
        yield this.session.loadMe.perform();
      }
      try {
        let [accessProfile, companyConfig, user, socketAuthenticated] = yield Ember.RSVP.all([this.store.loadRecord('access-profile', 'accessProfiles/me'), this.store.loadRecord('company-configuration', 'companyConfigurations/me'), this.store.findRecord('user', this.session.user.id), this.socket.authenticateSocket()]);
        if (!user) {
          return this.session.invalidate();
        }
        if (accessProfile.closedIfAway && this.session.user.away) {
          yield this.swal.warning('Entre em  contato com seu administrador do sistema para mais informações', {
            title: 'Seu perfil não tem acesso no momento'
          });
          return this.session.invalidate();
        }
        if (!companyConfig.hasProjectManagerModule || !accessProfile.admin && !accessProfile.allowCreateProjects) {
          yield this.swal.warning('Entre em contato com seu administrador do sistema para mais informações', {
            title: 'Você não tem acesso a este módulo'
          });
          let href = '';
          if (companyConfig.hasSocialModule) {
            href = _environment.default.appUrl.social;
          } else if (companyConfig.hasPortalGestorModule) {
            href = _environment.default.appUrl.conta;
          } else if (companyConfig.hasAVDModule) {
            href = _environment.default.appUrl.avd;
          } else if (companyConfig.hasTEDModule) {
            href = _environment.default.appUrl.ted;
          } else if (companyConfig.hasPesquisaModule) {
            href = _environment.default.appUrl.pesquisa;
          } else {
            href = _environment.default.appUrl.agenda;
          }
          return document.location.href = href;
        } else {
          this.accessProfile = accessProfile;
          this.companyConfig = companyConfig;
          this.user = user;
          this.isLoaded = true;
          return {
            accessProfile,
            companyConfig,
            user,
            socketAuthenticated
          };
        }
      } catch (e) {
        Ember.debug(`Permission service :: load :: ${e}`);
        throw e;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "admin", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "load", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype)), _class));
  _exports.default = PermissionService;
});