define("gestor-projetos/pods/indicators/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "X1b+pXyk",
    "block": "{\"symbols\":[\"@task\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-truncate max-w-120\"],[8],[1,[23,1,[\"taskId\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-truncate max-w-180\"],[11,\"title\",[23,1,[\"taskTitle\"]]],[8],[1,[23,1,[\"taskTitle\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-truncate max-w-200\"],[11,\"title\",[23,1,[\"userResponsible\"]]],[8],[1,[23,1,[\"userResponsible\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-truncate max-w-120\"],[8],[1,[23,1,[\"initialDate\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-truncate max-w-120\"],[8],[1,[23,1,[\"conclusionDate\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-truncate max-w-180\"],[8],[1,[23,1,[\"taskColumn\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-truncate max-w-180\"],[11,\"title\",[23,1,[\"milestoneTitle\"]]],[8],[1,[23,1,[\"milestoneTitle\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-truncate max-w-180\"],[11,\"title\",[23,1,[\"badgesTitle\"]]],[8],[1,[23,1,[\"badgesTitle\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/indicators/item/template.hbs"
    }
  });
  _exports.default = _default;
});