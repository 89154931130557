define("gestor-projetos/pods/project/index/milestone/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4O/Lcwyy",
    "block": "{\"symbols\":[\"Modal\",\"milestone\"],\"statements\":[[5,\"ui-modal\",[[12,\"data-backdrop\",\"static\"]],[[\"@open\",\"@modalSize\",\"@onCloseModal\"],[true,\"modal-lg\",[28,\"fn\",[[23,0,[\"closeModal\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"Milestones\"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-right mb-2\"],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"btn btn-sm btn-primary\"]],[[\"@route\"],[\"project.index.milestone.create\"]],{\"statements\":[[0,\"\\n        Adicionar milestone\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"ul\",true],[10,\"class\",\"list-group\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"milestones\"]]],null,{\"statements\":[[0,\"        \"],[5,\"project/index/milestone/item\",[],[[\"@milestone\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"        \"],[7,\"li\",true],[10,\"class\",\"list-group-item text-center font-italic\"],[8],[0,\"\\n          Nenhuma milestone cadastrada\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/milestone/index/template.hbs"
    }
  });
  _exports.default = _default;
});