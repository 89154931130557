define("gestor-projetos/pods/indicators/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember.computed.alias('model'), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, (_class = class IndicatorsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "filters", _descriptor2, this);
      _initializerDefineProperty(this, "openModalPrint", _descriptor3, this);
      _initializerDefineProperty(this, "pdf", _descriptor4, this);
      _initializerDefineProperty(this, "initialDate", _descriptor5, this);
      _initializerDefineProperty(this, "conclusionDate", _descriptor6, this);
      _initializerDefineProperty(this, "usersSelected", _descriptor7, this);
      _initializerDefineProperty(this, "taskColumnsSelected", _descriptor8, this);
      _initializerDefineProperty(this, "milestonesSelected", _descriptor9, this);
      _initializerDefineProperty(this, "badgesSelected", _descriptor10, this);
      _initializerDefineProperty(this, "createdAt", _descriptor11, this);
      _initializerDefineProperty(this, "finalizedAt", _descriptor12, this);
      _initializerDefineProperty(this, "showArchivedTasksOptions", _descriptor13, this);
      _initializerDefineProperty(this, "showArchivedTasks", _descriptor14, this);
      _initializerDefineProperty(this, "filterByDate", _descriptor15, this);
      _defineProperty(this, "exportFieldsFilteredByDate", [{
        field: 'taskId',
        fieldName: 'Código'
      }, {
        field: 'taskTitle',
        fieldName: 'Tarefa'
      }, {
        field: 'initialDate',
        fieldName: 'Data inicial'
      }, {
        field: 'conclusionDate',
        fieldName: 'Data final'
      }, {
        field: 'createdAt',
        fieldName: 'Data de criação'
      }, {
        field: 'finalizedAt',
        fieldName: 'Data de finalização'
      }, {
        field: 'userResponsible',
        fieldName: 'Responsável'
      }, {
        field: 'taskColumn',
        fieldName: 'Coluna'
      }]);
      _defineProperty(this, "exportFieldsNotFilteredByDate", [{
        field: 'taskId',
        fieldName: 'Código'
      }, {
        field: 'taskTitle',
        fieldName: 'Tarefa'
      }, {
        field: 'taskColumn',
        fieldName: 'Coluna'
      }, {
        field: 'userResponsible',
        fieldName: 'Responsável'
      }, {
        field: 'initialDate',
        fieldName: 'Data inicial'
      }, {
        field: 'conclusionDate',
        fieldName: 'Data final'
      }, {
        field: 'milestoneTitle',
        fieldName: 'Milestone'
      }, {
        field: 'badgesTitle',
        fieldName: 'Etiqueta'
      }]);
      _initializerDefineProperty(this, "project", _descriptor16, this);
    }
    *watchFilterByDate() {
      yield this.clearFilters();
    }
    clearFilters() {
      this.filters = {};
      this.initialDate = null;
      this.conclusionDate = null;
      this.usersSelected = null;
      this.taskColumnsSelected = null;
      this.milestonesSelected = null;
      this.badgesSelected = null;
      this.showArchivedTasks = null;
      this.taskId = null;
      this.taskTitle = null;
      this.createdAt = null;
      this.finalizedAt = null;
    }
    getFilters() {
      let filters = {};
      if (this.initialDate) {
        filters.initialDate = this.initialDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.conclusionDate) {
        filters.conclusionDate = this.conclusionDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.createdAt) {
        filters.createdAt = this.createdAt.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.finalizedAt) {
        filters.finalizedAt = this.finalizedAt.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.usersSelected) {
        filters.usersSelected = this.usersSelected.mapBy('id');
      }
      if (this.taskColumnsSelected) {
        filters.taskColumnsSelected = this.taskColumnsSelected.mapBy('id');
      }
      if (this.milestonesSelected) {
        filters.milestonesSelected = this.milestonesSelected.mapBy('id');
      }
      if (this.badgesSelected) {
        filters.badgesSelected = this.badgesSelected.mapBy('id');
      }
      if (this.showArchivedTasks) {
        if (this.showArchivedTasks === "Sim") {
          filters.showArchivedTasks = true;
        } else {
          filters.showArchivedTasks = null;
        }
      }
      this.filters = filters;
    }
    setInitialDate(_ref) {
      let {
        date
      } = _ref;
      this.initialDate = (0, _moment.default)(date);
      this.filterInitialAndConclusionDate();
    }
    setConclusionDate(_ref2) {
      let {
        date
      } = _ref2;
      this.conclusionDate = (0, _moment.default)(date);
      this.filterInitialAndConclusionDate();
    }
    setFilterCreatedAt(_ref3) {
      let {
        date
      } = _ref3;
      this.createdAt = (0, _moment.default)(date);
      this.filterCreatedAtAndFinalizedAt();
    }
    setFilterFinalizedAt(_ref4) {
      let {
        date
      } = _ref4;
      this.finalizedAt = (0, _moment.default)(date);
      this.filterCreatedAtAndFinalizedAt();
    }
    filterInitialAndConclusionDate() {
      if (!this.initialDate && !this.conclusionDate) {
        return;
      } else if ((0, _moment.default)(this.initialDate).isAfter(this.conclusionDate)) {
        this.swal.warning('A data final não pode ser anterior a data inicial');
        return;
      }
      this.getFilters();
    }
    filterCreatedAtAndFinalizedAt() {
      if (!this.createdAt && !this.finalizedAt) {
        return;
      } else if ((0, _moment.default)(this.createdAt).isAfter(this.finalizedAt)) {
        return this.swal.warning('A data final não pode ser anterior a data inicial');
      }
      this.getFilters();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "openModalPrint", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pdf", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "conclusionDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "usersSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "taskColumnsSelected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "milestonesSelected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "badgesSelected", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "finalizedAt", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showArchivedTasksOptions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['Sim', 'Não'];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "showArchivedTasks", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "filterByDate", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "watchFilterByDate", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "watchFilterByDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInitialDate", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "setInitialDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setConclusionDate", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "setConclusionDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFilterCreatedAt", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "setFilterCreatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFilterFinalizedAt", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "setFilterFinalizedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterInitialAndConclusionDate", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "filterInitialAndConclusionDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterCreatedAtAndFinalizedAt", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "filterCreatedAtAndFinalizedAt"), _class.prototype)), _class));
  _exports.default = IndicatorsController;
});