define("gestor-projetos/locales/pt-br/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    /* Configuração da bandeira da lĩngua */
    "locale.flag": "/images/pt-br_flag.png",
    /*Palavras genericas*/
    "add": "Adicionar",
    "active": "Ativo",
    "action": "Ação",
    "actions": "Ações",
    "admin": "Administrador",
    "about": "Sobre",
    /* C */
    "cancel": "Cancelar",
    "close": "Fechar",
    "create": "Cadastrar",
    "company": "Empresa",
    "change": "Alterar",
    "conclusionDate": "Data de Conclusão",
    "city": "Cidade",
    "color": "Cor",
    "cnpj": "CNPJ",
    "closed": "Encerrado",
    "content": "Conteúdo",
    "confirm": "Confirmar",
    /* D */
    "description": "Descrição",
    "destroy": "Deletar",
    "day": "Dia",
    "de": "de",
    "date": "Data",
    "delete": "Excluir",
    /* E */
    "email": "Email",
    "endDate": "Data Final",
    "edit": "Editar",
    /* F */
    "find": "Exibir",
    "finish": "Finalizar",
    "first": "Primeiro",
    /* G */
    "group": "Grupo",
    /* H */
    "home": "Home",
    /* I */
    "initialDate": "Data Inicial",
    "indicators": "Indicadores",
    "informations": "Informações",
    "information": "Informação",
    /* L */
    "last": "Último",
    "lastName": "Sobrenome",
    "link": "Link",
    "list": "Lista",
    /* M */
    "month": "Mês",
    "message": "Mensagem",
    "member": "Membro",
    /* N */
    "name": "Nome",
    "number": "Número",
    "new": "Novo(a)",
    "next": "Próximo",
    "no": "Não",
    /* O */
    "order": "Ordem",
    "open": "Aberto",
    /* P */
    "password": "Senha",
    "personalDocument": "CPF",
    "phone": "Telefone",
    "photos": "Fotos",
    "photo": "Foto",
    "profile": "Profile",
    "platform": "Plataforma",
    "projects": "Projetos",
    "project": "Projeto",
    "publications": "Publicações",
    "publish": "Publicar",
    "previous": "Anterior",
    "priority": "Prioridade",
    "policy": "Politica",
    "progress": "Andamento",
    "participants": "Participantes",
    /* R */
    "reason": "Motivo",
    "responsible": "Responsável",
    "requests": "Solicitações",
    "request": "Solicitação",
    "rooms": "Salas",
    /* S */
    "search": "Procurar",
    "status": "Status",
    "select": "Selecionar",
    "survey": "Enquetes",
    "save": "Salvar",
    "settings": "Configurações",
    "summary": "Resumo",
    "subscribers": "Inscritos",
    "started.in": "Inícia na",
    /* T */
    "task": "Tarefa",
    "time": "Hora",
    "trainings": "Treinamentos",
    "tasks": "Tarefas",
    "title": "Título",
    "training": "Capacitação",
    "type": "Tipo",
    "to": "Até",
    "trainers": "Instrutores",
    /* U */
    "user": "Usuário",
    "users": "Usuários",
    "update": "Atualizar",
    /* V */
    "toview": "Visualizar",
    /* w */
    "work": "Trabalho",
    "week": "Semana",
    /* Z */
    "zipcode": "CEP",
    /* Y */
    "yes": "Sim",
    /*Fim palavras genericas*/

    /*Palavras Compostas*/
    "aboutUser": "Sobre o usuário",
    "admissionDate": "Data de Admissão",
    "aditionalData": "Dados Adicionais",
    "adminConfiguration": "Administração/Configuração",
    "albumPhoto": "Album de Fotos",
    /* B */
    "birthDate": "Data de Nascimento",
    "birthCity": "Cidade de Nascimento",
    /* C */
    "corporatePortal": "Portal Corporativo",
    "confirmPassword": "Confirmação nova Senha",
    "contactData": "Dados do Contato",
    /* D */
    "descriptionActivity": "Descrição Atividades",
    /* F */
    "favoriteSport": "Esporte Favorito",
    "favoriteFood": "Comida Favorita",
    "finishMessage": "Finalizar Recado",
    /* G */
    "generateCommitment": "Gerar Compromisso",
    /* H */
    "heartTime": "Time do coração",
    /* J */
    "jobContract": "Contrato de Trabalho",
    /* L */
    "listEmployees": "Lista de Colaboradores",
    "literacy": "Grau de Instrução",
    "linkFacebook": "Link Facebook",
    "linkTwitter": "Link Twitter",
    "linkGoogle": "Link Google Plus",
    "linkInstagram": "Link Instagram",
    "linkLinkedin": "Link Linkedin",
    "personalEmail": "Email Pessoal",
    "sendMessage": "Enviar Mensagem",
    "showAllAppointments": "Ver todos os Compromissos",
    /* M */
    "messagesHr": "Solicitações RH",
    "messagesRegistered": "Recados Cadastrados",
    "messageBoards": "Mural de Recados",
    "myPage": "Minha Página",
    "myMessage": "Meu Recado",
    "messageInformation": "Informações do Recado",
    /* N */
    "newPassword": "Nova Senha",
    "noRecordsFound": "Sem registros cadastrados",
    "noAppointments": "Sem compromissos Cadastrados",
    /* P */
    "policyCompany": "Politicas da Empresa",
    "personalData": "Dados Pessoais",
    "professionalData": "Dados Profissionais",
    "professionalExperience": "Experiência Profissional",
    "particularInformation": "Informações Particulares",
    /* R */
    "readBooks": "Livros Lidos",
    "registrationData": "Dados Cadastrais",
    "receiptPayment": "Recibo de Pagamento",
    "sugestionBox": "Caixa de Sugestões",
    "userInformation": "Informações do Usuário",
    "write.something.for": "Escreva algo para",
    /*Fim Palavras Compostas*/

    /* LOGIN */
    "login.username": "Usuário",
    "login.password": "Senha",
    "login.keep.connected": "Manter Conectado",
    "login.password.recover": "Recuperar Senha",
    "login.a.solution": "Uma solução",
    /* FIM - LOGIN */

    /*Project work*/
    "project.edit.project": "Editar Projeto",
    "project.delete.project": "Excluir Projeto",
    "project.end.project": "Finalizar Projeto",
    "project.select.project": "Selecione um projeto",
    "project.add.list.task": "Adicionar lista de Tarefas",
    "project.list.project": "Lista de Tarefas",
    "project.name.list": "Nome da Lista",
    "project.tooltip.play": "Iniciar desenvolvimento Lista",
    "project.add.task": "Adicionar Tarefa",
    "project.select.view": "Selecione um projeto para visualizar as tarefas",
    "project.work.in": "Trabalhando em",
    "project.open.task": "Tarefa Aberta",
    "project.no.task": "Sem tarefas",
    "project.edit.list": "Editar Lista",
    "project.delete.list": "Excluir Lista",
    "project.low": "Baixa",
    "project.high": "Alta",
    "project.medium": "Média",
    "project.add.column": "Add. Coluna",
    "project.edit.column": "Editar Coluna",
    "project.delete.column": "Excluir Coluna",
    "project.subtask": "Subtarefa",
    "project.clone": "Clonar projeto"
    /*Fim Project work*/
  };
  _exports.default = _default;
});