define("gestor-projetos/pods/project/index/task/edit/history/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Single line computed properties
    histories: Ember.computed.sort('task.taskHistories', 'historySorting'),
    historySorting: Ember.A(['date:desc']),
    task: Ember.computed.alias('model')
  });
  _exports.default = _default;
});