define("gestor-projetos/mixins/dragdrop-column", ["exports", "sortablejs"], function (_exports, _sortablejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line
  var _default = Ember.Mixin.create({
    connectWith: Ember.computed('connected', 'selector', function () {
      if (this.connected) {
        return this.selector;
      }
      return '';
    }),
    selector: Ember.computed('parentModel', function () {
      return `.${this.parentModel}-sortable`;
    }),
    makeSortable(options) {
      this.setProperties(options);
      var self = this;
      var el = this.$(this.sortableContainer)[0];
      _sortablejs.default.create(el, {
        group: this.connectWith,
        animation: 150,
        handle: '.kb-drag-handle',
        ghostClass: 'kb-ghost',
        chosenClass: 'kb-dragging',
        setData: (dataTransfer, dragEl) => {
          var childId = $(dragEl).data('id');
          dataTransfer.setData(`kb/${self.get('childModel')}`, childId);
        },
        onAdd(evt) {
          self.beginPropertyChanges();
          self.set('promisesForAdding', self.updateChildrenPositions());
          Ember.RSVP.all(self.get('promisesForAdding')).then(() => {
            var childId = $(evt.item).data('id');
            var childModel = self.get('childModel');
            var parentModel = self.get('parentModel');
            self.moveItem(childId, self.get(parentModel), childModel).then(() => {
              self.endPropertyChanges();
            });
          });
        },
        onUpdate() {
          Ember.RSVP.all(self.updateChildrenPositions()).then(() => {});
        }
      });
    },
    updateChildrenPositions() {
      let childModel = this.childModel;
      let childSelector = this.childSelector;
      let promises = [];
      $(childSelector).each((index, cardEl) => {
        let childId = $(cardEl).data('id');
        promises.push(this.store.find(childModel, childId).then(child => {
          child.set('order', index);
          return child.save();
        }));
      });
      return promises;
    },
    moveItem(itemId, newParent, childModel) {
      return new Ember.RSVP.Promise(resolve => {
        if (!itemId) {
          resolve();
        } else {
          this.store.find(childModel, itemId).then(item => {
            newParent.get('children').then(newItems => {
              item.get('parent').then(oldParent => {
                oldParent.get('children').then(oldItems => {
                  newItems.pushObject(item);
                  oldItems.removeObject(item);
                  item.set('parent', newParent);
                  item.save().then(() => resolve());
                });
              });
            });
          });
        }
      });
    }
  });
  _exports.default = _default;
});