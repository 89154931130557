define("gestor-projetos/services/contact-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    isListLoaded: false,
    users: [],
    groups: [],
    loadList() {
      this.set('isListLoaded', false);
      Ember.RSVP.all([this.ajax.request('/chat/users'), this.ajax.request('/chat/usergroups')]).then(response => {
        this.set('users', response[0].users);
        this.set('groups', response[1].groups);
        this.set('isListLoaded', true);
      });
    },
    setUserOnline(userId) {
      var list = this.users;
      var user = list.findBy('id', parseInt(userId));
      var index = list.indexOf(user);
      list.removeObject(user);
      Ember.set(user, 'connected', true);
      if (index !== -1) {
        list.insertAt(index, user);
      } else {
        list.insertAt(0, user);
      }
    },
    setUserOffline(userId) {
      var list = this.users;
      var user = list.findBy('id', parseInt(userId));
      var index = list.indexOf(user);
      list.removeObject(user);
      Ember.set(user, 'connected', false);
      if (index !== -1) {
        list.insertAt(index, user);
      } else {
        list.insertAt(0, user);
      }
    },
    filterUsers(filter) {
      var list = this.users;
      return list.filter(function (item) {
        if (item.name && item.name.match(new RegExp(filter, 'i')) || item.professionalEmail && item.professionalEmail.match(new RegExp(filter, 'i')) || item.jobFunction && item.jobFunction.match(new RegExp(filter, 'i')) || item.department && item.department.match(new RegExp(filter, 'i')) || item.professionalPhone && item.professionalPhone.match(new RegExp(filter, 'i'))) {
          return true;
        } else {
          return false;
        }
      });
    },
    filterGroups(filter) {
      var list = this.groups;
      return list.filter(function (item) {
        if (item.name && item.name.match(new RegExp(filter, 'i'))) {
          return true;
        } else {
          return false;
        }
      });
    },
    getUser(id) {
      return this.users.findBy('id', parseInt(id));
    },
    getGroup(id) {
      return this.groups.findBy('id', parseInt(id));
    },
    increaseUnread(message) {
      var unread = 0;
      if (message.type === 'direct') {
        var user = this.getUser(message.from);
        unread = user.unread + 1;
        Ember.set(user, 'unread', unread);
      }
      if (message.type === 'group') {
        var group = this.getGroup(message.userGroup);
        unread = group.unread + 1;
        Ember.set(group, 'unread', unread);
      }
    },
    clearUnread(from, type) {
      if (type === 'direct') {
        var user = this.getUser(from);
        Ember.set(user, 'unread', 0);
      }
      if (type === 'group') {
        var group = this.getGroup(from);
        Ember.set(group, 'unread', 0);
      }
    }
  });
  _exports.default = _default;
});