define("gestor-projetos/services/ajax-account", ["exports", "gestor-projetos/services/ajax", "gestor-projetos/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    host: _environment.default.apiUrl.conta
  });
  _exports.default = _default;
});