define("gestor-projetos/pods/components/task-timer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aAjYVZbg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"runningWorkRecord\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"fas fa-stop mr-1\"],[8],[9],[0,\"\\n  \"],[1,[23,0,[\"formatedTime\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"fas fa-play mr-1\"],[8],[9],[0,\"\\n  Iniciar\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/components/task-timer/template.hbs"
    }
  });
  _exports.default = _default;
});