define("gestor-projetos/pods/project/index/milestone/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Single line computed properties
    milestone: Ember.computed.alias('model'),
    project: Ember.computed.alias('milestone.project'),
    // Actions
    actions: {
      closeModal() {
        this.transitionToRoute('project.index');
      },
      removeTask(task) {
        this.milestone.tasks.removeObject(task);
        task.save();
      },
      selectTask(task) {
        this.milestone.tasks.addObject(task);
        task.save();
      }
    }
  });
  _exports.default = _default;
});