define("gestor-projetos/models/project-custom-field", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    name: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    project: (0, _model.belongsTo)('project')
  });
  _exports.default = _default;
});