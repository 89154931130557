define("gestor-projetos/pods/project/index/default-checklists/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProjectIndexDefaultChecklistsCreateRoute extends Ember.Route {
    model() {
      const project = this.modelFor('project');
      const defaultChecklist = this.store.createRecord('default-checklist', {
        project
      });
      this.store.createRecord('default-checklist-item', {
        defaultChecklist,
        order: 0
      });
      return defaultChecklist;
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.modelFor('project').reload();
    }
  }
  _exports.default = ProjectIndexDefaultChecklistsCreateRoute;
});