define("gestor-projetos/pods/project/index/task/form/checklist/item/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['list-group-item', 'list-group-item-action', 'd-flex', 'align-items-center'],
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Single line computed properties
    project: Ember.computed.alias('checklist.project'),
    task: Ember.computed.alias('checklist.task'),
    // Tasks
    removeItem: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.checklistItem.isNew) {
          return this.checklistItem.rollbackAttributes();
        }
        let {
          value
        } = yield this.swal.confirm('Confirmar remoção deste item do checklist?');
        if (!value) {
          return;
        }
        yield this.ajax.put(`checklistItem/${this.checklistItem.id}/softDeleteCheckListItem`);
        this.removeChecklistItem(this.checklistItem);
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        let taskHistory = this.store.createRecord('task-history', {
          user,
          date: (0, _moment.default)().toDate(),
          task: this.task,
          checklist: this.checklist,
          checklistItem: this.checklistItem,
          type: this.checklistItem = 'remove-checklist-item'
        });
        yield taskHistory.save();
        this.toast.success('Item removido do checklist');
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      let isNew = this.checklistItem.isNew;
      try {
        if (!this.checklistItem.title) {
          return this.swal.warning(`Informe um título para o item do checklist.`);
        }
        yield (0, _emberConcurrency.timeout)(800);
        yield this.checklistItem.save();
        yield this.store.findRecord('checklistItem', this.checklistItem.id);
        this.store.pushPayload({
          checklistItem: this.checklistItem
        });
        if (isNew) {
          let user = this.store.peekRecord('user', this.session.user.id);
          if (!user) {
            user = yield this.store.findRecord('user', this.session.user.id);
          }
          let taskHistory = this.store.createRecord('task-history', {
            user,
            date: (0, _moment.default)().toDate(),
            task: this.task,
            checklist: this.checklist,
            checklistItem: this.checklistItem,
            type: this.checklistItem = 'create-checklist-item'
          });
          yield taskHistory.save();
        }
      } catch (e) {
        this.checklistItem.rollbackAttributes();
        this.swal.catch(e);
      }
    }).restartable(),
    toggleItem: (0, _emberConcurrency.task)(function* () {
      this.toggleProperty('checklistItem.done');
      try {
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        let taskHistory = this.store.createRecord('task-history', {
          user,
          date: (0, _moment.default)().toDate(),
          task: this.task,
          checklist: this.checklist,
          checklistItem: this.checklistItem,
          type: this.checklistItem.done ? 'finished-checklist-item' : 'open-checklist-item'
        });
        yield taskHistory.save();
        this.save.perform();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    itemFocus(element) {
      if (!this.checklistItem.isNew) {
        return;
      }
      element.focus();
    },
    // Actions
    actions: {
      createItem() {
        this.save.perform();
        if (this.create) {
          this.create();
          this.historyCreateItem.perform();
        }
      },
      setDate(_ref) {
        let {
          date
        } = _ref;
        Ember.set(this, 'checklistItem.endDate', date);
        this.save.perform();
        this.historyCreateItem.perform();
      }
    }
  });
  _exports.default = _default;
});