define("gestor-projetos/services/project-presentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { inject as service } from '@ember/service';
  // import { get, set } from '@ember/object';
  // import { debug } from '@ember/debug';
  // import { task, timeout } from 'ember-concurrency';
  var _default = Ember.Service.extend({
    // ajax: service(),
    // router: service('-routing'), // Débito técnico :(
    // store: service(),
    //
    // hasPresentations: false,
    // isPresenting: false,
    // _presentation: null,
    //
    // init() {
    //   this._super(...arguments);
    //
    //   get(this, '_getPresentations').perform();
    // },
    //
    // start() {
    //   get(this, '_presentationTask').perform();
    //   set(this, 'isPresenting', true);
    // },
    //
    // stop() {
    //   get(this, '_presentationTask').cancelAll();
    //   get(this, 'router').transitionTo('project');
    //   set(this, 'isPresenting', false);
    // },
    //
    // _getPresentations: task(function * () {
    //   try {
    //     let rawPresentation = yield get(this, 'ajax').request('/projectPresentations/available');
    //
    //     if (!rawPresentation) { return; }
    //
    //     get(this, 'store').pushPayload(rawPresentation)
    //     let presentation = get(this, 'store').peekRecord('project-presentation', rawPresentation.projectPresentation.id);
    //
    //     set(this, 'hasPresentations', true);
    //     set(this, '_presentation', presentation);
    //   } catch (e) {
    //     debug(e);
    //   }
    // }),
    //
    // _presentationTask: task(function * () {
    //   let presentation = get(this, '_presentation');
    //   let transitionTime = get(presentation, 'transitionTime') * 1000;
    //   let projects = get(presentation, 'projects');
    //
    //   let projectsLength = projects.length;
    //
    //   for (let i = 0; i < projectsLength; i++) {
    //     let project = projects[i];
    //     get(this, 'router').transitionTo('project-view', [project]);
    //     yield timeout(transitionTime);
    //     if (i === projectsLength - 1) { i = -1; }
    //   }
    // }).drop(),
  });
  _exports.default = _default;
});