define("gestor-projetos/pods/project/header/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LKHOIeS+",
    "block": "{\"symbols\":[\"Dropdown\",\"&attrs\",\"&default\"],\"statements\":[[5,\"basic-dropdown\",[[13,2]],[[],[]],{\"statements\":[[0,\"\\n  \"],[14,3,[[28,\"hash\",null,[[\"Content\",\"Item\",\"Trigger\"],[[28,\"component\",[\"project/header/filter/content\"],[[\"dropdown\"],[[23,1,[]]]]],[28,\"component\",[\"project/header/filter/item\"],null],[23,1,[\"Trigger\"]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/header/filter/template.hbs"
    }
  });
  _exports.default = _default;
});