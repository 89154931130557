define("gestor-projetos/pods/project/index/task/edit/related/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['col-lg-4', 'col-md-6', 'mb-3']
  });
  _exports.default = _default;
});