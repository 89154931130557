define("gestor-projetos/pods/project/header/filter/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SOSk2E1X",
    "block": "{\"symbols\":[\"@current\",\"&default\",\"@activeClass\",\"&attrs\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"btn btn-block rounded-lg border border-2 mb-1 \",[28,\"if\",[[23,0,[\"isSelected\"]],[23,3,[]]],null]]]],[13,4],[3,\"did-insert\",[[23,0,[\"colorElement\"]],[23,1,[\"color\"]]]],[3,\"on\",[\"click\",[23,0,[\"handleSelect\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isSelected\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-check \",[23,1,[\"textColor\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/header/filter/item/template.hbs"
    }
  });
  _exports.default = _default;
});