define("gestor-projetos/components/gantt-line-title", ["exports", "nela-gantt/components/gantt-line-title"], function (_exports, _ganttLineTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ganttLineTitle.default;
    }
  });
});