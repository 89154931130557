define("gestor-projetos/pods/project/index/milestone/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      if (this.milestone.validations.isInvalid) {
        return this.swal.validation(this.milestone.validations);
      }
      let message = this.milestone.isNew ? 'Milestone cadastrada' : 'Milestone atualizada';
      try {
        yield this.milestone.save();
        this.toast.success(message);
        this.router.transitionTo('project.index.milestone.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      closeModal() {
        this.router.transitionTo('project.index');
      }
    }
  });
  _exports.default = _default;
});