define("gestor-projetos/helpers/app-url", ["exports", "ui-nela/helpers/app-url"], function (_exports, _appUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "appUrl", {
    enumerable: true,
    get: function () {
      return _appUrl.appUrl;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _appUrl.default;
    }
  });
});