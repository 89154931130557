define("gestor-projetos/utils/validate-cpf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = TestaCPF;
  /**
   * Verifica se CPF é válido
   * Retirado de http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
   * Em: 04/05/2017
   */
  function TestaCPF(strCPF) {
    var Soma, Resto, i;
    Soma = 0;
    //strCPF  = RetiraCaracteresInvalidos(strCPF,11);
    if (strCPF === "00000000000") {
      return false;
    }
    for (i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
    Resto = Soma * 10 % 11;
    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }
    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      return false;
    }
    Soma = 0;
    for (i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
    Resto = Soma * 10 % 11;
    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }
    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      return false;
    }
    return true;
  }
});