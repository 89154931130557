define("gestor-projetos/pods/project/index/badges/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rkXUpZb1",
    "block": "{\"symbols\":[\"@badge\"],\"statements\":[[5,\"input-color\",[],[[\"@color\",\"@onChange\"],[[23,1,[\"color\"]],[28,\"action\",[[23,0,[]],\"colorChanged\"],null]]]],[0,\"\\n\\n\"],[5,\"input\",[[12,\"class\",\"h5 flex-fill border-0 text-body bg-transparent w-100 mb-0 mx-2\"]],[[\"@value\",\"@input\",\"@placeholder\"],[[23,1,[\"title\"]],[28,\"perform\",[[23,0,[\"save\"]]],null],\"Nome da etiqueta\"]]],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"isNew\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-outline-primary mr-1\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"save\"]]],null]],[10,\"title\",\"Criar\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-check\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-outline-danger\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"removeBadge\"]]],null]],[10,\"title\",\"Remover\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/badges/item/template.hbs"
    }
  });
  _exports.default = _default;
});