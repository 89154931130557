define("gestor-projetos/pods/project/index/default-checklists/form/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fjNJymsn",
    "block": "{\"symbols\":[\"@checklistItem\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 mb-1\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"d-flex justify-content-between\"],[8],[0,\"\\n      \"],[5,\"input\",[[12,\"class\",\"form-control mr-2\"]],[[\"@type\",\"@placeholder\",\"@value\"],[\"text\",\"Insira o título do item\",[23,1,[\"title\"]]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"args\",\"checklist\",\"project\",\"hasChecklistDate\"]]],null,{\"statements\":[[0,\"        \"],[5,\"nela-datepicker\",[[12,\"class\",\"mr-1\"]],[[\"@allowClear\",\"@triggerClass\",\"@placeholder\",\"@selected\",\"@minDate\",\"@maxDate\"],[true,\"form-control\",\"Selecionar data\",[23,1,[\"endDate\"]],[23,0,[\"args\",\"checklist\",\"project\",\"initialDate\"]],[23,0,[\"args\",\"checklist\",\"project\",\"conclusionDate\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n        \"],[7,\"button\",true],[11,\"disabled\",[23,0,[\"onlyOneItem\"]]],[10,\"class\",\"btn btn-sm btn-outline-danger ml-2 mt-1\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"removeItem\"]]],null]],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/default-checklists/form/item/template.hbs"
    }
  });
  _exports.default = _default;
});