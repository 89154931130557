define("gestor-projetos/pods/components/task-copy/component", ["exports", "ember-concurrency", "jquery"], function (_exports, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: '',
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    shouldCopyChecklists: false,
    shouldKeepCreator: false,
    // Single line computed properties
    project: Ember.computed.reads('task.project'),
    taskColumn: Ember.computed.reads('task.taskColumn'),
    // Lifecycle hooks
    didInsertElement() {
      // TODO: Remover utilização do jQuery
      Ember.run.later(() => {
        (0, _jquery.default)('#copy-popover').webuiPopover({
          title: 'Copiar',
          url: '#copy-popover-content',
          closeable: true,
          placement: 'left'
        });
      });
      this.loadUser.perform();
    },
    // Tasks
    copyTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.swal.loading('Clonando tarefa');
        let project = Ember.get(this, 'project.id');
        let taskColumn = Ember.get(this, 'taskColumn.id');
        let response = yield this.ajax.post(`tasks/${this.task.id}/clone`, {
          data: {
            project,
            taskColumn,
            meta: {
              shouldCopyChecklists: this.shouldCopyChecklists,
              shouldKeepCreator: this.shouldKeepCreator
            }
          }
        });
        (0, _jquery.default)('#copy-popover').trigger('click');
        this.store.pushPayload(response);
        let column = this.store.peekRecord('task-column', taskColumn);
        let task = this.store.peekRecord('task', response.task.id);
        column.trigger('copyTask', task);
        this.toast.success('Tarefa clonada');
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    loadUser: (0, _emberConcurrency.task)(function* () {
      try {
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        Ember.set(this, 'user', user);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      setColumnSelected() {
        try {
          const taskColumns = this.project.get('taskColumns').toArray();
          Ember.set(this, 'taskColumn', taskColumns[0]);
        } catch (error) {
          console.log(error);
        }
      }
    }
  });
  _exports.default = _default;
});