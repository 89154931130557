define("gestor-projetos/pods/project/index/settings/custom-field/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['list-group-item', 'px-0'],
    // Services
    swal: Ember.inject.service(),
    // Multiline computed properties
    fieldOptions: Ember.A([{
      label: 'Texto',
      value: 'text'
    }, {
      label: 'Numérico',
      value: 'number'
    }, {
      label: 'Data',
      value: 'date'
    }, {
      label: 'Moeda',
      value: 'currency'
    }, {
      label: 'Horas',
      value: 'time'
    }]),
    typeSelected: Ember.computed('projectCustomField.type', function () {
      return this.fieldOptions.findBy('value', this.projectCustomField.type);
    }),
    // Tasks
    removeField: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.projectCustomField.id) {
          let {
            value
          } = yield this.swal.confirm('Confirmar remoção do campo personalizado?', {
            text: this.projectCustomField.name
          });
          if (!value) {
            return;
          }
        }
        this.fields.removeObject(this.projectCustomField);
        yield this.projectCustomField.destroyRecord();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});