define("gestor-projetos/pods/project/index/default-checklists/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iXrjYbvN",
    "block": "{\"symbols\":[\"@checklist\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex justify-content-between border-bottom py-2\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"d-flex flex-wrap max-w-650\"],[8],[0,\"\\n    \"],[7,\"h6\",true],[10,\"class\",\"mt-2 ml-2 text-truncate\"],[11,\"title\",[23,1,[\"title\"]]],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"d-flex justify-content-between\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn btn-link\"],[12,\"title\",\"Visualizar itens\"]],[[\"@route\",\"@model\"],[\"project.index.default-checklists.show\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"far fa-list-ul\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"dropdown\"],[8],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"btn btn-link\"],[11,\"id\",[29,[[23,0,[\"elementId\"]],\"-dropdown\"]]],[10,\"title\",\"Opções\"],[10,\"data-toggle\",\"dropdown\"],[10,\"aria-haspopup\",\"true\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"far fa-ellipsis-v\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"dropdown-menu\"],[11,\"aria-labelledby\",[29,[[23,0,[\"elementId\"]],\"-dropdown\"]]],[10,\"style\",\"width: 100px !important;\"],[8],[0,\"\\n        \"],[5,\"link-to\",[[12,\"class\",\"dropdown-item\"]],[[\"@route\",\"@model\"],[\"project.index.default-checklists.edit\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"Editar\"]],\"parameters\":[]}],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"dropdown-item\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"delete\"]]],null]],[8],[0,\"Excluir\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/default-checklists/item/template.hbs"
    }
  });
  _exports.default = _default;
});