define("gestor-projetos/pods/404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uBc4Z09W",
    "block": "{\"symbols\":[\"Page\"],\"statements\":[[5,\"ui-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"section\"]],[[12,\"class\",\"text-center\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-meh fa-4x text-primary mb-3\"],[8],[9],[0,\"\\n\\n    \"],[7,\"h2\",true],[8],[0,\"Página não encontrada\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"      \"],[5,\"link-to\",[],[[\"@route\"],[\"home\"]],{\"statements\":[[0,\"Voltar para página inicial\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"link-to\",[],[[\"@route\"],[\"login\"]],{\"statements\":[[0,\"Ir para página de login\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/404/template.hbs"
    }
  });
  _exports.default = _default;
});