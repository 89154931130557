define("gestor-projetos/pods/components/input-mentions/component", ["exports", "ember-concurrency", "jquery"], function (_exports, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML Attributes
    classNames: ['input-mention'],
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    creatingMention: null,
    selectedUsers: Ember.A(),
    usersToSelect: Ember.computed.alias('loadUsersToSelect.lastSuccessful.value'),
    mentionTextStylized: Ember.computed('value', 'mentions.[]', function () {
      let text = this.value;
      let mentions = this._getMentions().sortBy('start');
      let diff = 0;
      if (!text || !mentions) {
        return;
      }
      mentions.forEach(mention => {
        let start = mention.start + diff;
        let end = mention.end + diff;
        let name = text.substring(start, end);
        if (name !== mention.userName) {
          return;
        }
        let span = `<span class="mention">%name</span>`;
        diff += span.length - 5;
        span = span.replace('%name', name);
        text = text.substring(0, start) + span + text.substring(end, text.length);
      });

      // Converte quebra de linha em tag <br>
      text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
      return text;
    }),
    // Lifecycle Methods
    didInsertElement() {
      this._super(...arguments);
      this.registerEvents();
      let $textarea = (0, _jquery.default)(`#${this.elementId} textarea`);
      $textarea.on('scroll', () => {
        let $element = (0, _jquery.default)(`#${this.elementId} .input-mention__stylist`);
        $element.scrollTop($textarea.scrollTop());
      });
      this.setFocusElement();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this._removeMentionsNotInGroup();
      this.setFocusElement();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.unregisterEvents();
    },
    // Event Methods
    registerEvents() {
      let input = this._getInput();
      if (!input) {
        return;
      }

      /* A regra de apagar as mentions deve ficar no evento keydown
      devido ao valor recebido do selectionStart e selectionEnd. */
      input.addEventListener('keydown', e => this.onKeyDown(e));
      input.addEventListener('input', e => this.onInput(e));
      input.addEventListener('paste', e => this.onPaste(e));
      input.addEventListener('dblclick', () => {
        Ember.debug(this._getMentions());
      });
    },
    unregisterEvents() {
      let input = this._getInput();
      if (!input) {
        return;
      }
      input.removeEventListener('keydown', null, false);
      input.removeEventListener('input', null, false);
      input.removeEventListener('paste', null, false);
      input.removeEventListener('dblclick', null, false);
    },
    actualizeMentionsAfterEnter(position) {
      let mentions = this._getMentions().sortBy('start');
      mentions.forEach(mention => {
        if (mention.end > position) {
          mention.start++;
          mention.end++;
        }
      });
      Ember.set(this, 'mentions', mentions);
    },
    onKeyDown(e) {
      let creatingMention = this.creatingMention;
      let keyGroups = this._geyKeyGroup(e);
      let {
        selectionStart,
        selectionEnd
      } = this._normalizePositions(e);
      let sendOnEnter = this.sendOnEnter;
      if (keyGroups.isEnter) {
        if (creatingMention) {
          let userSelected = this.usersToSelect.findBy('selected', true);
          if (userSelected) {
            this.send('selectUser', userSelected);
            e.preventDefault();
          }
        } else if (!keyGroups.isShift && sendOnEnter) {
          e.preventDefault();
          sendOnEnter();
        } else if (selectionStart === selectionEnd) {
          this.actualizeMentionsAfterEnter(selectionStart);
        }
      } else if (keyGroups.isKeyUpOrDown) {
        this._controlMentionList(e);
      } else if (keyGroups.isCharacter) {
        if (selectionStart !== selectionEnd) {
          this._updatePositionsOnDeleteText(selectionStart, selectionEnd);
        }
      } else if (keyGroups.isDeleteOrBackpace) {
        if (creatingMention) {
          creatingMention = creatingMention.substr(0, creatingMention.length - 1);
        }
        this._removeMentionsInPositions(selectionStart, selectionEnd);
        this._updatePositionsOnDeleteText(selectionStart, selectionEnd);
        Ember.set(this, 'creatingMention', creatingMention);
      }
    },
    onInput(e) {
      let creatingMention = this.creatingMention;
      let selectionStart = e.target.selectionStart;
      let selectionEnd = e.target.selectionEnd;
      if (e.data === '@') {
        creatingMention = '@';
        Ember.set(this, 'mentionStart', selectionStart);
      } else if (e.data && creatingMention) {
        creatingMention += e.data;
      } else if (e.data && !creatingMention) {
        this._updatePositionsOnInsertText(selectionStart, selectionEnd);
      }
      this._removeMentionsInPositions(selectionStart);
      Ember.set(this, 'creatingMention', creatingMention);
      this.loadUsersToSelect.perform();
    },
    onPaste(e) {
      let clipboardData, pastedData;
      let {
        selectionStart
      } = this._normalizePositions(e);
      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');
      this._removeMentionsInPositions(selectionStart);
      this._updatePositionsOnInsertText(selectionStart, selectionStart + pastedData.length);
    },
    // Methods
    _geyKeyGroup(e) {
      let keyGroups = {};
      keyGroups.isEnter = e.which === 13;
      keyGroups.isKeyUpOrDown = e.which === 40 || e.which === 38;
      keyGroups.isDeleteOrBackpace = e.which === 46 || e.which === 8;
      keyGroups.isShift = e.shiftKey;
      if (e.which !== 46 && (e.which > 40 && e.which <= 126 || e.which === 32)) {
        keyGroups.isCharacter = true;
      }
      return keyGroups;
    },
    _getMentions() {
      if (this.mentions) {
        let mentions = [];
        if (typeof this.mentions === 'string') {
          mentions = JSON.parse(this.mentions);
        } else {
          mentions = this.mentions;
        }
        let retorno = mentions.map(o => {
          o.start = Number(o.start);
          o.end = Number(o.end);
          return o;
        });
        return retorno;
      } else {
        return Ember.A();
      }
    },
    _getInput() {
      return document.querySelector(`#${this.elementId} textarea`);
    },
    _controlMentionList(e) {
      let usersToSelect = this.usersToSelect;
      let currentSelect = usersToSelect.findBy('selected', true);
      if (!usersToSelect || !currentSelect) {
        return;
      }
      let listIndex = usersToSelect.indexOf(currentSelect);

      // 40 = Down Key; 38 = Up Key.
      listIndex = e.which === 40 ? listIndex + 1 : listIndex - 1;
      let objectOfPosition = usersToSelect.objectAt(listIndex);
      if (objectOfPosition) {
        usersToSelect.forEach(o => Ember.set(o, 'selected', false));
        Ember.set(objectOfPosition, 'selected', true);
      }
      event.preventDefault();
    },
    /* Remove todas as mentions dentro das posições informadas. */
    _removeMentionsInPositions(start, end) {
      let mentions = this._getMentions();
      let mentionsFound = mentions.filter(mention => {
        let mentionStart = Number(mention.start) + 1;
        let mentionEnd = Number(mention.end);
        return start >= mentionStart && start < mentionEnd || end > mentionStart && end < mentionEnd || start <= mentionStart && end > mentionEnd;
      });
      mentions.removeObjects(mentionsFound);
      Ember.set(this, 'mentions', mentions);
    },
    /* Atualiza a posição das mentions quando apagar algum texto do comentário */
    _updatePositionsOnDeleteText(selectionStart, selectionEnd) {
      let mentions = this._getMentions();
      let textSize = selectionEnd - selectionStart || 1;

      /* Cada emoji conta como dois caracteres, verifica se no texto removido há algum emoji e para cada um
      adiciona uma posição a mais no textSize */
      let removedText = this.value.substring(selectionStart, selectionStart + textSize) || '';
      removedText.split('').forEach(c => {
        if (c.charCodeAt(0) > 255) {
          textSize++;
        }
      });
      mentions = mentions.map(mention => {
        let mentionStart = Number(mention.start);
        let mentionEnd = Number(mention.end);
        if (mentionStart >= selectionStart && selectionStart > -1) {
          mention.start = mentionStart - textSize;
          mention.end = mentionEnd - textSize;
        }
        return mention;
      });
      Ember.set(this, 'mentions', mentions);
    },
    /* Atualiza a posição das mentions quando inserir algum texto no comentário */
    _updatePositionsOnInsertText(selectionStart, selectionEnd) {
      let mentions = this._getMentions();
      let textSize = selectionEnd - selectionStart || 1;
      mentions = mentions.map(mention => {
        let mentionStart = Number(mention.start);
        let mentionEnd = Number(mention.end);
        if (mentionStart + 1 >= selectionStart) {
          mention.start = mentionStart + textSize;
          mention.end = mentionEnd + textSize;
        }
        return mention;
      });
      Ember.set(this, 'mentions', mentions);
    },
    _normalizePositions(e) {
      let {
        selectionStart,
        selectionEnd
      } = e.target;
      if (e.key === 'Backspace') {
        if (selectionStart === selectionEnd) {
          selectionStart--;
          selectionEnd--;
        }
      }
      return {
        selectionStart,
        selectionEnd
      };
    },
    _removeMentionsNotInGroup() {
      const mentions = this._getMentions();
      if (this.group) {
        const groupName = Ember.get(this.group, 'name');
        if (groupName !== 'Toda a empresa' && groupName !== 'ALL') {
          if (mentions.length > 0 && Ember.get(this.group, 'id')) {
            const groupUserIds = Ember.get(this.group, 'userIds');
            const mentionsNotInGroup = this.mentions.filter(mention => {
              return !groupUserIds.includes(Number(mention.userId));
            });
            if (mentionsNotInGroup.length > 0) {
              this.swal.warning(`Usuários marcados que não fazem parte do grupo ${groupName} terão suas marcações removidas!`);
              mentions.removeObjects(mentionsNotInGroup);
              Ember.set(this, 'mentions', mentions);
            }
          }
        }
      }
    },
    setFocusElement() {
      const element = document.getElementById(`input-comment-task${this.inputId}`);
      if (element) {
        element.focus();
      }
    },
    // Taks
    loadUsersToSelect: (0, _emberConcurrency.task)(function* () {
      try {
        let creatingMention = this.creatingMention;
        creatingMention = creatingMention && creatingMention.replace('@', '');
        let users;
        if (Ember.get(this, 'project.public')) {
          if (creatingMention.length < 3) {
            return;
          }
          users = yield this.store.loadRecords('user', 'users/ajax', {
            term: creatingMention
          });
          Ember.set(this, 'users', users);
        } else {
          const projectUsers = Ember.get(this, 'project.users');
          users = projectUsers.filter(user => {
            return Ember.get(user, 'fullName').toLowerCase().indexOf(creatingMention) >= 0;
          });
        }
        if (users.length) {
          const selectedUsers = users.filter(user => user.selected);
          if (selectedUsers.length) {
            selectedUsers.forEach(user => Ember.set(user, 'selected', false));
          }
          Ember.set(users.objectAt(0), 'selected', true);
        }
        return users;
      } catch (e) {
        Ember.debug(e);
        console.log(e);
      }
    }),
    // Actions
    actions: {
      selectUser(user) {
        let input = this._getInput();
        if (!input) {
          return;
        }
        let mentions = this._getMentions();
        let creatingMention = this.creatingMention;
        let mentionStart = this.mentionStart;
        let userName = `${user.firstName} ${user.lastName}`;
        let mentionEnd = mentionStart + userName.length;
        let value = this.value;
        value = value.replace(creatingMention, userName);
        this._updatePositionsOnInsertText(mentionStart, mentionEnd);
        mentions.pushObject({
          userId: user.id,
          userName: userName,
          start: mentionStart - 1,
          end: mentionEnd - 1
        });
        Ember.set(this, 'creatingMention', null);
        Ember.set(this, 'value', value);
        Ember.set(this, 'mentions', mentions);
        input.focus();
      }
    }
  });
  _exports.default = _default;
});