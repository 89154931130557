define("gestor-projetos/utils/swal-error", ["exports", "sweet-alert2"], function (_exports, _sweetAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(response) {
    let messages = '';
    if (response.errors && response.errors.length) {
      response.errors.forEach(function (error) {
        if (error.detail) {
          messages += error.detail + '\n';
        } else {
          messages += error.title + '\n';
        }
      });
    } else {
      messages += 'Erro desconhecido';
    }
    _sweetAlert.default.fire('Ops!', messages, 'error');
  }
});