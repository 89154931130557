define("gestor-projetos/pods/project/header/filter/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let FilterItemComponent = (_dec = Ember.computed('args.{current,selected.[]}'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class FilterItemComponent extends _component.default {
    get isSelected() {
      if (!this.args.selected) {
        return false;
      }
      return this.args.selected.findBy('id', this.args.current.id);
    }
    colorElement(element, _ref) {
      let [color] = _ref;
      if (color) {
        element.setAttribute('style', `background:${color};`);
      }
    }
    handleSelect() {
      if (this.isSelected) {
        this.args.selected.removeObject(this.args.current);
      } else {
        this.args.selected.addObject(this.args.current);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "colorElement", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "colorElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSelect", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleSelect"), _class.prototype)), _class));
  _exports.default = FilterItemComponent;
});