define("gestor-projetos/models/task-column", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'A coluna deve ter um nome'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    allowCopyToProject: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    allowNextColumn: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isClone: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    allowsCreateTask: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    close: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    description: (0, _model.attr)('string'),
    forcesComment: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    keepTaskCreator: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    notifyCreator: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    open: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    order: (0, _model.attr)('number'),
    progress: (0, _model.attr)('boolean'),
    public: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // deprecated
    restrictMove: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    restrictedToFlow: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    restrictedToUsers: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    sendFeedback: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    title: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    copyToColumn: (0, _model.belongsTo)('task-column', {
      inverse: null
    }),
    parentId: (0, _model.belongsTo)('task-column', {
      inverse: null
    }),
    copyToProject: (0, _model.belongsTo)('project', {
      inverse: null
    }),
    nextColumn: (0, _model.belongsTo)('task-column', {
      inverse: null
    }),
    project: (0, _model.belongsTo)('project', {
      inverse: 'taskColumns'
    }),
    canReceiveFrom: (0, _model.hasMany)('task-column', {
      inverse: null
    }),
    tasks: (0, _model.hasMany)('task'),
    responsibleUsers: (0, _model.hasMany)('user', {
      inverse: null
    }),
    // Computed properties
    color: Ember.computed(function () {
      let num = Math.round(Math.random() * Math.pow(10, 7));
      let hexString = `#${num.toString(16)}`;
      return hexString;
    })
  });
  _exports.default = _default;
});