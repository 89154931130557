define("gestor-projetos/services/ajax", ["exports", "ember-ajax/services/ajax", "gestor-projetos/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    // Services
    session: Ember.inject.service(),
    // Default values
    host: _environment.default.apiUrl.projetos,
    // Computed properties
    headers: Ember.computed('session.data.authenticated.token', {
      get() {
        let headers = {};
        if (this.session.data.authenticated.token) {
          headers['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
        }
        return headers;
      }
    }),
    isUnauthorizedError( /*status, headers, payload*/
    ) {
      let isUnauthorized = this._super(...arguments);
      if (isUnauthorized && this.session.isAuthenticated) {
        this.session.invalidate();
      }
      return isUnauthorized;
    }
  });
  _exports.default = _default;
});