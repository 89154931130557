define("gestor-projetos/pods/application/sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "M5C7AaqF",
    "block": "{\"symbols\":[\"Menu\",\"module\"],\"statements\":[[5,\"ui-sidebar\",[],[[\"@linkTo\"],[\"home\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"modules\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[\"type\"]],\"conta\"],null]],null,{\"statements\":[[4,\"if\",[[23,0,[\"canAccessAccountModule\"]]],null,{\"statements\":[[0,\"        \"],[6,[23,1,[\"item\"]],[],[[\"@text\",\"@icon\",\"@link\",\"@showIf\"],[[23,2,[\"title\"]],[23,2,[\"icon\"]],[28,\"app-url\",[[23,2,[\"type\"]]],null],true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,1,[\"item\"]],[],[[\"@text\",\"@icon\",\"@link\",\"@showIf\"],[[23,2,[\"title\"]],[23,2,[\"icon\"]],[28,\"app-url\",[[23,2,[\"type\"]]],null],true]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/application/sidebar/template.hbs"
    }
  });
  _exports.default = _default;
});