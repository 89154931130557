define("gestor-projetos/pods/project/index/archived/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2i1yqVoQ",
    "block": "{\"symbols\":[\"Modal\",\"task\"],\"statements\":[[5,\"ui-modal\",[[12,\"class\",\"ui-modal--right\"],[12,\"data-backdrop\",\"static\"]],[[\"@open\",\"@onCloseModal\"],[true,[28,\"action\",[[23,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"project.index.options\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fas fa-arrow-left mr-2\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    Tarefas arquivadas\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[[12,\"class\",\"ui-scrollbar overflow-auto\"],[3,\"did-insert\",[[28,\"action\",[[23,0,[]],[23,0,[\"handleScroll\"]]],null]]]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"tasks\"]]],null,{\"statements\":[[0,\"        \"],[5,\"project/task\",[],[[\"@task\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"        \"],[7,\"li\",true],[10,\"class\",\"font-italic text-center text-muted\"],[8],[0,\"Nenhuma tarefa arquivada\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"viewMore\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"button\",true],[10,\"class\",\"btn btn-link btn-block\"],[11,\"onclick\",[28,\"perform\",[[23,0,[\"loadTasks\"]]],null]],[8],[0,\"\\n            Ver mais\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/archived/template.hbs"
    }
  });
  _exports.default = _default;
});