define("gestor-projetos/pods/project/column/component", ["exports", "ember-concurrency", "sortablejs"], function (_exports, _emberConcurrency, _sortablejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['kanban-column', 'py-2', 'draggable'],
    // Services
    ajax: Ember.inject.service(),
    modal: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    socket: Ember.inject.service('socket-project'),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    createTask: false,
    skip: 0,
    // Multiline computed properties
    activeTasks: Ember.computed('tasks.@each.{archived,order}', function () {
      return this.tasks.filterBy('archived', false).sortBy('order');
    }),
    allowMove: Ember.computed('isColumnResponsible', 'isAdminOnProject', 'taskColumn.restrictMove', function () {
      let restrictMove = Boolean(this.taskColumn.restrictMove);
      let admin = Boolean(this.isAdminOnProject);
      let responsible = Boolean(this.isColumnResponsible);
      return admin || responsible || !restrictMove;
    }),
    allowPut: Ember.computed('taskColumn.{canReceiveFrom.[],restrictedToFlow}', function () {
      if (!this.taskColumn.restrictedToFlow) {
        return true;
      }
      return this.taskColumn.canReceiveFrom.map(column => {
        return `column-${column.id}`;
      });
    }),
    columnTitle: Ember.computed('taskColumn.title', 'tasksQnt', 'tasks.length', function () {
      let str = this.taskColumn.title;
      if (this.tasksQnt && this.tasks.length) {
        str += ` (${this.tasksQnt})`;
      }
      return str;
    }),
    isAdminOnProject: Ember.computed('project.usersAdmin.[]', 'session.user.id', function () {
      let admin = this.project.usersAdmin.findBy('id', String(this.session.user.id));
      return Boolean(admin);
    }),
    isColumnResponsible: Ember.computed('session.user.id', 'taskColumn.responsibleUsers.[]', function () {
      let userId = String(this.session.user.id);
      return (this.taskColumn.responsibleUsers || []).findBy('id', userId);
    }),
    showCreate: Ember.computed('isAdminOnProject', 'isColumnResponsible', 'taskColumn.allowsCreateTask', 'project.invitedCanCreateTasks', function () {
      return this.taskColumn.allowsCreateTask && (this.isAdminOnProject || this.isColumnResponsible || this.project.invitedCanCreateTasks);
    }),
    viewMore: Ember.computed('tasks.[]', 'tasksQnt', function () {
      return this.tasks.length < this.tasksQnt;
    }),
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      this.tasks = Ember.A();
    },
    didInsertElement() {
      this._super(...arguments);
      this.loadTasks.perform();
      this._handleScroll();
      this._initSortable();
      this._initSocketTriggers();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.filterTasks.perform();
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.createTask) {
        this.createTask.rollbackAttributes();
      }
    },
    // Tasks
    archiveTasks: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Deseja arquivar todas as tarefas desta coluna?');
        if (!value) {
          return;
        }
        this.swal.loading('Arquivando tarefas');
        yield this.ajax.put(`taskColumns/${this.taskColumn.id}/archiveTasks`);
        this.toast.success('Tarefas arquivadas com sucesso');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    filterTasks: (0, _emberConcurrency.task)(function* () {
      const url = `taskColumns/${this.taskColumn.id}/tasks`;
      const query = {
        archived: 0,
        sort: 'order ASC',
        term: this.searchTask,
        filterBy: {
          badges: this.filterBy.badges.mapBy('id'),
          members: this.filterBy.members.mapBy('id'),
          milestones: this.filterBy.milestones.mapBy('id')
        }
      };
      if (this.searchTask) {
        yield (0, _emberConcurrency.timeout)(800);
      } else {
        query.limit = 15;
      }
      if (query.filterBy.badges.length || query.filterBy.members.length || query.filterBy.milestones.length) {
        delete query.limit;
      }
      try {
        const tasks = yield this.store.loadRecords('task', url, query);
        Ember.setProperties(this, {
          tasks,
          tasksQnt: tasks.meta.total
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable(),
    handleMove: (0, _emberConcurrency.task)(function* (evt) {
      let isSameColumn = evt.to === evt.from;
      let task = evt.item.dataset.task;
      let column = evt.to.dataset.column;
      let order = evt.newDraggableIndex;
      let taskColumn = this.store.peekRecord('task-column', column);
      task = this.store.peekRecord('task', task);
      let comment;
      if (!isSameColumn && (taskColumn.forcesComment || task.late && this.project.commentWhenLate)) {
        try {
          // TODO: Remover utilização do 'ember-modal-service'
          let {
            text,
            mentions
          } = yield this.modal.open('dialogs/imposed-comment', {
            mentions: Ember.A(),
            project: this.project
          });
          comment = this.store.createRecord('task-comment', {
            mentions,
            task,
            text
          });
        } catch (e) {
          let element = evt.from.children[evt.oldIndex];
          if (!element) {
            return evt.from.appendChild(evt.item);
          }
          return evt.from.insertBefore(evt.item, element);
        }
      }
      try {
        this.swal.loading('Movendo tarefa', {
          allowOutsideClick: false,
          allowEscapeKey: false
        });
        yield this.ajax.put(`tasks/move`, {
          data: {
            order,
            column,
            task: task.id
          }
        });
        yield this.store.findRecord('task', task.id);
        this.swal.close();
        if (isSameColumn) {
          return;
        }
        if (comment) {
          yield comment.save();
        }
        if (!taskColumn.allowCopyToProject || !taskColumn.copyToProject) {
          return;
        }
        let project = yield taskColumn.copyToProject;
        if (!project || !taskColumn.copyToColumn) {
          return;
        }
        let copyToColumn = yield taskColumn.copyToColumn;
        if (!copyToColumn) {
          return;
        }
        yield this.ajax.post(`/tasks/${task.id}/clone`, {
          data: {
            project: project.id,
            taskColumn: copyToColumn.id,
            meta: {
              shouldKeepCreator: true,
              shouldCopyChecklists: true
            }
          }
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    loadTasks: (0, _emberConcurrency.task)(function* () {
      const url = `taskColumns/${this.taskColumn.id}/tasks`;
      try {
        let tasks = yield this.store.loadRecords('task', url, {
          archived: 0,
          limit: 15,
          skip: this.skip,
          sort: 'order ASC'
        });
        this.tasks.addObjects(tasks);
        Ember.setProperties(this, {
          skip: this.skip + 15,
          tasksQnt: tasks.meta.total
        });
      } catch (e) {
        Ember.debug(`<Project::Index::Column> :: loadTasks :: ${e}`);
      }
    }).enqueue(),
    removeTaskColumn: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar remoção da coluna?', {
          text: 'Todas as tarefas serão removidas'
        });
        if (!value) {
          return;
        }
        yield this.taskColumn.destroyRecord();
        this.toast.success('Coluna removida');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.swal.loading('Criando tarefa');
        if (this.createTask.validations.isInvalid) {
          return this.swal.validation(this.createTask.validations);
        }
        yield this.createTask.save();
        Ember.set(this, 'createTask', null);
        this.toast.success('Tarefa adicionada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Custom methods
    _handleScroll() {
      this.element.querySelector('ul').addEventListener('scroll', e => {
        let column = e.target;
        let scrollBottom = column.offsetHeight + column.scrollTop;
        if (this.viewMore && scrollBottom > column.scrollHeight - 300) {
          this.loadTasks.perform();
        }
      });
    },
    _initSocketTriggers() {
      this.taskColumn.on('addTaskViaSocket', _ref => {
        let {
          task,
          count
        } = _ref;
        let element = this.element.querySelector(`li[data-task='${task.id}']`);
        if (element) {
          element.remove();
        }
        this.tasks.addObject(task);
        Ember.set(this, 'tasksQnt', count);
      });
      this.taskColumn.on('deleteTaskViaSocket', _ref2 => {
        let {
          task
        } = _ref2;
        task = this.tasks.findBy('id', String(task.id));
        this.tasks.removeObject(task);
        Ember.set(this, 'tasksQnt', this.tasksQnt - 1);
      });
      this.taskColumn.on('removeTaskViaSocket', _ref3 => {
        let {
          task,
          count
        } = _ref3;
        this.tasks.removeObject(task);
        Ember.set(this, 'tasksQnt', count);
      });
      this.taskColumn.on('createTaskViaSocket', _ref4 => {
        let {
          task,
          count
        } = _ref4;
        this.tasks.insertAt(0, task);
        Ember.set(this, 'tasksQnt', count);
      });
      this.taskColumn.on('copyTask', task => {
        this.tasks.addObject(task);
        if (this.isDestroyed) {
          return;
        }
        let newQnt = this.tasksQnt + 1;
        Ember.set(this, 'tasksQnt', newQnt);
      });
    },
    _initSortable() {
      let column = this.element.querySelector('ul');
      new _sortablejs.default.create(column, {
        animation: 200,
        dataIdAttr: 'data-task',
        direction: 'vertical',
        draggable: '.draggable',
        group: {
          name: `column-${this.taskColumn.id}`,
          pull: this.allowMove,
          put: this.allowPut
        },
        sort: this.allowMove,
        onAdd: evt => {
          this.handleMove.perform(evt);
        },
        onUpdate: evt => {
          this.handleMove.perform(evt);
        }
      });
    },
    autofocus(element) {
      element.focus();
    },
    updateSort(element) {
      let sortable = _sortablejs.default.get(element);
      sortable.option('sort', this.allowMove);
      sortable.option('group', {
        name: `column-${this.taskColumn.id}`,
        pull: this.allowMove,
        put: this.allowPut
      });
    },
    // Actions
    actions: {
      cancelTask() {
        this.createTask.rollbackAttributes();
        Ember.set(this, 'createTask', null);
      },
      createOnEnter(_, e) {
        if (e.keyCode === 13) {
          e.preventDefault();
          this.saveTask.perform();
        }
      },
      createTask() {
        if (this.project.advancedTaskCreation) {
          return this.router.transitionTo('project.index.task.create', {
            queryParams: {
              taskColumn: this.taskColumn.id
            }
          });
        }
        let task = this.store.createRecord('task', {
          project: Ember.get(this, 'taskColumn.project'),
          taskColumn: this.taskColumn
        });
        Ember.set(this, 'createTask', task);
      }
    }
  });
  _exports.default = _default;
});