define("gestor-projetos/pods/project/index/default-checklists/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
    }
    async closeModal() {
      const {
        checklist
      } = this.args;
      const checklistItems = checklist.defaultChecklistItems.toArray();
      for (const item of checklistItems) {
        if (item.hasDirtyAttributes) {
          await item.destroyRecord();
        }
      }
      this.router.transitionTo('project.index.default-checklists.index');
    }
    createItem() {
      const {
        checklist
      } = this.args;
      let item = this.store.createRecord('default-checklist-item', {
        defaultChecklist: checklist,
        order: checklist.defaultChecklistItems.length
      });
      checklist.defaultChecklistItems.addObject(item);
    }
    *save() {
      const {
        checklist
      } = this.args;
      if (!checklist.title) {
        return this.toast.warning('Informe o título do checklist');
      }
      const defaultChecklistItems = checklist.defaultChecklistItems.toArray();
      if (defaultChecklistItems?.length) {
        for (const item of defaultChecklistItems) {
          if (!item.title) {
            return this.toast.warning('Informe o título do item');
          }
        }
      }
      try {
        yield checklist.save();
        const defaultChecklistItems = checklist.defaultChecklistItems.toArray();
        if (defaultChecklistItems?.length) {
          for (const item of defaultChecklistItems) {
            yield item.save();
          }
        }
        const message = checklist.isNew ? 'Checklist padrão cadastrado' : 'Checklist padrão atualizado';
        this.toast.success(message);
        this.closeModal();
      } catch (error) {
        return this.swal.warning(error);
      }
    }
    *autosave() {
      const {
        checklist
      } = this.args;
      try {
        yield (0, _emberConcurrency.timeout)(200);
        yield checklist.save();
      } catch (error) {
        return this.swal.warning(error);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createItem", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "createItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autosave", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "autosave"), _class.prototype)), _class));
  _exports.default = FormComponent;
});