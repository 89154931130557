define("gestor-projetos/pods/project/index/options/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Single line computed properties
    project: Ember.computed.alias('model'),
    // Multiline computed properties
    colors: Ember.A(['#156FB2', '#E83029', '#B0B21E', '#149080', '#08DDC1', '#FDFE77', '#B20A7F', '#31753B', '#CCA173', '#7C5AC2', '#627257', '#515572', '#CA9E8E', '#9A9FBF', '#EDF2F6']),
    isAdminOnProject: Ember.computed('project.usersAdmin.[]', function () {
      return this.project.usersAdmin.findBy('id', String(this.session.user.id));
    }),
    projectColor: Ember.computed('project.color', function () {
      let background = `background:${this.project.color}`;
      let classes = 'd-inline-block rounded border align-text-top mr-2';
      return Ember.String.htmlSafe(`<span class="${classes}" style="${background};width:20px;height:20px;"/>`);
    }),
    // Tasks
    changeColor: (0, _emberConcurrency.task)(function* (color) {
      try {
        Ember.set(this, 'project.color', color);
        yield this.project.save();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Custom methods
    colorSpan(element, _ref) {
      let [color] = _ref;
      element.setAttribute('style', `background:${color};width:40px;height:40px;`);
    },
    // Actions
    actions: {
      closeModal() {
        this.transitionToRoute('project.index');
      }
    }
  });
  _exports.default = _default;
});