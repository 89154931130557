define("gestor-projetos/pods/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bm7aFuQO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid px-5\"],[8],[0,\"\\n  \"],[5,\"project/header\",[],[[\"@project\",\"@filter\"],[[23,0,[\"project\"]],[23,0,[\"filter\"]]]]],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/template.hbs"
    }
  });
  _exports.default = _default;
});