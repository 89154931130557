define("gestor-projetos/pods/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9XfIGZl+",
    "block": "{\"symbols\":[\"@checklist\",\"@progress\"],\"statements\":[[4,\"unless\",[[23,0,[\"onModal\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"\\n    \"],[7,\"small\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"d-flex align-items-center\"],[8],[0,\"\\n      \"],[7,\"small\",true],[10,\"class\",\"mr-2\"],[8],[1,[23,2,[]],false],[0,\"%\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"progress rounded-pill w-75 mr-2\"],[11,\"title\",[29,[[23,2,[]],\"%\"]]],[10,\"style\",\"height: 10px;\"],[8],[0,\"\\n        \"],[7,\"div\",false],[12,\"class\",\"progress-bar\"],[12,\"role\",\"progressbar\"],[12,\"aria-valuenow\",[23,2,[]]],[12,\"aria-valuemin\",\"0\"],[12,\"aria-valuemax\",\"100\"],[3,\"did-update\",[[28,\"fn\",[[23,0,[\"setWidth\"]]],null],[23,2,[]]]],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"setWidth\"]]],null],[23,2,[]]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\" \\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"small\",true],[10,\"class\",\"mr-2\"],[8],[1,[23,1,[\"progress\"]],false],[0,\"%\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"progress rounded-pill w-25\"],[11,\"title\",[29,[[23,1,[\"progress\"]],\"%\"]]],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"progress-bar\"],[12,\"role\",\"progressbar\"],[12,\"aria-valuenow\",[23,1,[\"progress\"]]],[12,\"aria-valuemin\",\"0\"],[12,\"aria-valuemax\",\"100\"],[3,\"did-update\",[[28,\"fn\",[[23,0,[\"setWidth\"]]],null],[23,1,[\"progress\"]]]],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"setWidth\"]]],null],[23,1,[\"progress\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/components/progress-bar/template.hbs"
    }
  });
  _exports.default = _default;
});