define("gestor-projetos/pods/components/search-bar/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SearchBar = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember.computed.empty('projects'), _dec15 = Ember.computed.filterBy('projects', 'checked'), _dec16 = Ember.computed.filterBy('users', 'checked'), _dec17 = Ember.computed.and('searchResults', 'searchResults.length'), _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = class SearchBar extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "users", _descriptor6, this);
      _initializerDefineProperty(this, "projects", _descriptor7, this);
      _initializerDefineProperty(this, "searchPage", _descriptor8, this);
      _initializerDefineProperty(this, "searchResults", _descriptor9, this);
      _initializerDefineProperty(this, "showSearchDialog", _descriptor10, this);
      _initializerDefineProperty(this, "showMoreProject", _descriptor11, this);
      _initializerDefineProperty(this, "showMoreResponsible", _descriptor12, this);
      _initializerDefineProperty(this, "showMoreTasksButton", _descriptor13, this);
      _initializerDefineProperty(this, "hasNoProjects", _descriptor14, this);
      _initializerDefineProperty(this, "filteredProjects", _descriptor15, this);
      _initializerDefineProperty(this, "filteredResponsables", _descriptor16, this);
      _initializerDefineProperty(this, "hasResults", _descriptor17, this);
    }
    *search() {
      const term = this.args.searchTerm;
      this.searchPage = 1;
      this.showMoreTasksButton = true;
      if (!term) {
        this.initFilters();
        return;
      }
      yield (0, _emberConcurrency.timeout)(500);
      const projects = this.filteredProjects.map(item => item.id);
      const users = this.filteredResponsables.map(item => item.id);
      const data = yield this.ajax.request('/search', {
        data: {
          term,
          users,
          projects
        }
      });
      if (data.tasks.length < 10) {
        this.showMoreTasksButton = false;
      }
      this.searchResults = data.tasks.toArray();
      this.showSearchDialog = true;
    }
    *showMoreTasks() {
      ++this.searchPage;
      const projects = this.filteredProjects.map(item => item.id);
      const users = this.filteredResponsables.map(item => item.id);
      const data = yield this.ajax.request('/search', {
        data: {
          users,
          projects,
          term: this.args.searchTerm,
          page: this.searchPage
        }
      });
      if (data.tasks.length < 10) {
        this.showMoreTasksButton = false;
      }
      this.searchResults = [...this.searchResults, ...data.tasks.toArray()];
    }
    *loadUsers() {
      const data = yield this.store.loadRecords('user', 'projects/usersResponsibles', {
        limit: 5
      });
      data.toArray().forEach(item => Ember.set(item, 'checked', false));
      this.users = data.toArray();
    }
    *loadProjects() {
      // !!!Remove o limit por causa dos checklists padrões, isso deve ser tratado no futuro
      const data = yield this.store.loadRecords('project', `users/${this.session.user.id}/projects`, {
        // limit: 10
      });
      data.toArray().forEach(item => Ember.set(item, 'checked', false));
      this.projects = data.toArray();
    }
    initFilters() {
      this.searchResults = null;
      this.showSearchDialog = false;
      this.loadUsers.perform();
      this.loadProjects.perform();
    }
    toggleMore(selectName) {
      this[selectName] = !this[selectName];
    }
    handleChangeResponsible(user) {
      const findedUser = this.users.find(item => item.id === user.id);
      if (findedUser) {
        Ember.set(findedUser, 'checked', true);
        this.search.perform();
        return;
      }
      user.checked = true;
      this.users.pushObject(user);
      this.search.perform();
    }
    redirectToLeaderless() {
      return this.router.transitionTo('leaderless');
    }
    redirectToProjects() {
      return this.router.transitionTo('home');
    }
    handleChangeProject(project) {
      const findedProject = this.projects.find(item => item.id === project.id);
      if (findedProject) {
        Ember.set(findedProject, 'checked', true);
        this.search.perform();
        return;
      }
      project.checked = true;
      this.projects.pushObject(project);
      this.search.perform();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "projects", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "searchPage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "searchResults", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showSearchDialog", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "showMoreProject", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showMoreResponsible", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showMoreTasksButton", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasNoProjects", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "filteredProjects", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "filteredResponsables", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hasResults", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "search", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showMoreTasks", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "showMoreTasks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadUsers", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadProjects", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadProjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initFilters", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "initFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMore", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChangeResponsible", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "handleChangeResponsible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToLeaderless", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToLeaderless"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToProjects", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToProjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChangeProject", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "handleChangeProject"), _class.prototype)), _class));
  _exports.default = SearchBar;
});