define("gestor-projetos/pods/project/index/default-checklists/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProjectIndexDefaultChecklistsEditRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = ProjectIndexDefaultChecklistsEditRoute;
});