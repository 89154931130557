define("gestor-projetos/pods/home/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HomeController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.computed('model.admins.@each.filed', 'showArchived'), _dec5 = Ember.computed('model.members.@each.filed', 'showArchived'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class HomeController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "showArchived", _descriptor2, this);
      _initializerDefineProperty(this, "showVideo", _descriptor3, this);
    }
    get adminOnProjects() {
      return this.model.admins.filter(project => {
        if (!project.filed) {
          return true;
        }
        return this.showArchived;
      });
    }
    get memberOnProjects() {
      return this.model.members.filter(project => {
        if (!project.filed) {
          return true;
        }
        return this.showArchived;
      });
    }
    hideEffect(e) {
      Ember.run.later(() => {
        e.classList.add('d-none');
      }, 2000);
    }
    afterDestroy(project) {
      this.projects.removeObject(project);
    }
    fixVideoOnScroll() {
      let elementMenu = document.getElementById('quick-tips');
      let {
        scrollTop
      } = document.documentElement;
      if (!elementMenu) {
        return;
      }
      if (scrollTop > 70) {
        elementMenu.classList.add('quick-tips__float-video--fixed');
      } else {
        elementMenu.classList.remove('quick-tips__float-video--fixed');
      }
    }
    removeListener() {
      document.removeEventListener('scroll', this.fixVideoOnScroll);
    }
    toggleShowFiled() {
      this.toggleProperty('showArchived');
      localStorage.setItem('showArchived', this.showArchived);
    }
    toggleVideo() {
      if (this.showVideo) {
        this.removeListener();
      } else {
        document.addEventListener('scroll', this.fixVideoOnScroll);
      }
      this.toggleProperty('showVideo');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showArchived", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return localStorage.getItem('showArchived') === 'true';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showVideo", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "adminOnProjects", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "adminOnProjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "memberOnProjects", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "memberOnProjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterDestroy", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "afterDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fixVideoOnScroll", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "fixVideoOnScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeListener", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "removeListener"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowFiled", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowFiled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleVideo", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleVideo"), _class.prototype)), _class));
  _exports.default = HomeController;
});