define("gestor-projetos/pods/project/index/milestone/burndown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CBQPxesw",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[5,\"ui-modal\",[[12,\"data-backdrop\",\"static\"]],[[\"@open\",\"@modalSize\",\"@onCloseModal\"],[true,\"modal-lg\",[28,\"action\",[[23,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"text-body\"]],[[\"@route\"],[\"project.index.milestone.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fas fa-arrow-left mr-2\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    Burndown: \"],[1,[23,0,[\"milestone\",\"title\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"ember-chart\",[],[[\"@type\",\"@data\"],[\"line\",[23,0,[\"burndown\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn btn-outline-primary\"]],[[\"@route\"],[\"project.index.milestone.index\"]],{\"statements\":[[0,\"Voltar\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/milestone/burndown/template.hbs"
    }
  });
  _exports.default = _default;
});