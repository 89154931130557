define("gestor-projetos/helpers/task-attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.taskAttachment = taskAttachment;
  function taskAttachment(params /*, hash*/) {
    switch (params[0]) {
      case 'txt':
        return '/images/icons/txt.png';
      case 'pdf':
        return '/images/icons/pdf.png';
      case 'jpeg':
        return '/images/icons/jpg.png';
      case 'jpg':
        return '/images/icons/jpg.png';
      case 'bmp':
        return '/images/icons/jpg.png';
      case 'ico':
        return '/images/icons/jpg.png';
      case 'png':
        return '/images/icons/png.png';
      case 'doc':
        return '/images/icons/doc.png';
      case 'docx':
        return '/images/icons/doc.png';
      case 'odt':
        return '/images/icons/doc.png';
      case 'csv':
        return '/images/icons/csv.png';
      case 'odp':
        return '/images/icons/xls.png';
      case 'xls':
        return '/images/icons/xls.png';
      case 'xlsx':
        return '/images/icons/xls.png';
      case 'ppsx':
        return '/images/icons/ppt.png';
      case 'ppt':
        return '/images/icons/ppt.png';
      case 'pptx':
        return '/images/icons/ppt.png';
      case 'rar':
        return '/images/icons/rar.png';
      case 'zip':
        return '/images/icons/zip.png';
      case '7z':
        return '/images/icons/zip.png';
      default:
        return '/images/icons/file.png';
    }
  }
  var _default = Ember.Helper.helper(taskAttachment);
  _exports.default = _default;
});