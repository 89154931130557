define("gestor-projetos/pods/application/controller", ["exports", "gestor-projetos/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    projectPresentation: Ember.inject.service('project-presentation'),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    searchTerm: '',
    isReady: Ember.computed.and('session.isAuthenticated', 'permission.isLoaded', 'session.user'),
    isProductionBuild: Ember.computed(function () {
      return _environment.default.environment === 'production';
    }),
    actions: {
      logout() {
        this.session.invalidate();
      },
      stopPresentation() {
        this.projectPresentation.stop();
      }
    }
  });
  _exports.default = _default;
});