define("gestor-projetos/pods/project/index/task/edit/history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o5fvTPc7",
    "block": "{\"symbols\":[\"taskHistory\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"list-group list-group-flush\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"histories\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"list-group-item\"],[8],[0,\"\\n      \"],[7,\"small\",true],[11,\"title\",[28,\"moment-format\",[[23,1,[\"date\"]],\"DD/MM/YYYY HH:mm\"],null]],[8],[0,\"\\n        \"],[1,[28,\"moment-from-now\",[[23,1,[\"date\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"h6\",true],[8],[1,[23,1,[\"text\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/index/task/edit/history/template.hbs"
    }
  });
  _exports.default = _default;
});