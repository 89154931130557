define("gestor-projetos/pods/application/notification/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GDeZHJeu",
    "block": "{\"symbols\":[\"@notification\"],\"statements\":[[7,\"li\",true],[11,\"class\",[29,[\"list-group-item px-0 pr-2 \",[23,0,[\"unreadClass\"]]]]],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"onclick\",[28,\"perform\",[[23,0,[\"setAsRead\"]]],null]]],[[\"@route\",\"@models\",\"@activeClass\"],[[23,0,[\"route\"]],[23,0,[\"models\"]],\"text-body\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"d-flex flex-column ml-2 mt-2\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mb-0 item-group-size\"],[8],[1,[28,\"html\",[[23,1,[\"message\"]]],null],false],[9],[0,\"\\n\\n      \"],[7,\"small\",true],[11,\"title\",[29,[[28,\"moment-format\",[[23,1,[\"createdAt\"]],\"DD/MM/YYYY HH:mm\"],null]]]],[8],[0,\"\\n        \"],[1,[28,\"moment-from-now\",[[23,1,[\"createdAt\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"unless\",[[23,1,[\"read\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"far fa-dot-circle small text-primary position-absolute t-2 r-2\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/application/notification/item/template.hbs"
    }
  });
  _exports.default = _default;
});