define("gestor-projetos/pods/project/index/column/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProjectIndexColumnController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('taskColumn.project'), _dec5 = Ember.computed.alias('model'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class ProjectIndexColumnController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "project", _descriptor4, this);
      _initializerDefineProperty(this, "taskColumn", _descriptor5, this);
    }
    *save() {
      if (this.taskColumn.validations.isInvalid) {
        return this.swal.validation(this.taskColumn.validations);
      }
      const nextColumn = yield this.taskColumn.nextColumn;
      if (this.taskColumn.allowNextColumn) {
        if (!nextColumn) {
          return this.swal.warning('É necessário selecionar uma coluna para próxima etapa');
        }
      }
      try {
        yield this.taskColumn.save();
        this.toast.success('Coluna atualizada');
        this.closeModal();
      } catch (e) {
        this.swal.catch(e);
      }
    }
    clearRestriction(selected) {
      if (!selected.length) {
        this.taskColumn.restrictedToUsers = false;
        this.taskColumn.restrictMove = false;
      }
    }
    closeModal() {
      this.transitionToRoute('project.index');
    }
    toggleClose() {
      if (this.taskColumn.close) {
        this.taskColumn.sendFeedback = false;
      }
      this.toggleProperty('taskColumn.close');
    }
    toggleCopyToProject() {
      this.toggleProperty('taskColumn.allowCopyToProject');
      if (!this.taskColumn.allowCopyToProject) {
        this.taskColumn.copyToColumn = null;
        this.taskColumn.copyToProject = null;
        this.taskColumn.keepTaskCreator = false;
      }
    }
    toggleFeedback() {
      if (!this.taskColumn.sendFeedback) {
        this.taskColumn.close = true;
      }
      this.toggleProperty('taskColumn.sendFeedback');
    }
    toggleMoveRestriction() {
      if (!this.taskColumn.restrictMove && !this.taskColumn.responsibleUsers.length) {
        return this.swal.warning('É necessário selecionar pelo menos um responsável pela coluna');
      }
      this.toggleProperty('taskColumn.restrictMove');
    }
    toggleNextColumn() {
      this.toggleProperty('taskColumn.allowNextColumn');
      this.taskColumn.nextColumn = null;
    }
    toggleRestrictedToFlow() {
      this.toggleProperty('taskColumn.restrictedToFlow');
      if (!this.taskColumn.restrictedToFlow) {
        this.taskColumn.canReceiveFrom.clear();
      }
    }
    toggleUserRestriction() {
      if (!this.taskColumn.restrictedToUsers && !this.taskColumn.responsibleUsers.length) {
        return this.swal.warning('É necessário selecionar pelo menos um responsável pela coluna');
      }
      this.toggleProperty('taskColumn.restrictedToUsers');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "taskColumn", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearRestriction", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "clearRestriction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleClose", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCopyToProject", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCopyToProject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleFeedback", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFeedback"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMoveRestriction", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMoveRestriction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleNextColumn", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleNextColumn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleRestrictedToFlow", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRestrictedToFlow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleUserRestriction", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleUserRestriction"), _class.prototype)), _class));
  _exports.default = ProjectIndexColumnController;
});