define("gestor-projetos/helpers/contrast-color", ["exports", "gestor-projetos/utils/contrast-color"], function (_exports, _contrastColor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function contrastColor(params) {
    return (0, _contrastColor.default)(params[0]);
  });
  _exports.default = _default;
});