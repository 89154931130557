define("gestor-projetos/pods/components/input-color/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7MGWtdiA",
    "block": "{\"symbols\":[\"selectionColor\",\"@color\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"dropright open\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"form-control\"],[12,\"id\",[29,[[23,0,[\"elementId\"]],\"-dropdown\"]]],[12,\"data-toggle\",\"dropdown\"],[12,\"aria-haspopup\",\"true\"],[12,\"aria-expanded\",\"false\"],[3,\"did-insert\",[[23,0,[\"colorSpan\"]],[23,2,[]]]],[3,\"did-update\",[[23,0,[\"colorSpan\"]],[23,2,[]]]],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"dropdown-menu\"],[11,\"aria-labelledby\",[29,[[23,0,[\"elementId\"]],\"-dropdown\"]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"colors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",false],[12,\"class\",\"input-color__span\"],[12,\"onclick\",[28,\"action\",[[23,0,[]],\"setColor\",[23,1,[]]],null]],[12,\"role\",\"button\"],[3,\"did-insert\",[[23,0,[\"colorSpan\"]],[23,1,[]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,2,[]],[23,1,[]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-check input-color__check \",[28,\"contrast-color\",[[23,1,[]]],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/components/input-color/template.hbs"
    }
  });
  _exports.default = _default;
});