define("gestor-projetos/models/company", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    tradingName: (0, _model.attr)('string'),
    standardName: (0, _model.attr)('string'),
    cnpj: (0, _model.attr)('string'),
    serial: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    complement: (0, _model.attr)('string'),
    zipcode: (0, _model.attr)('string'),
    neighborhood: (0, _model.attr)('string'),
    city: (0, _model.belongsTo)('city'),
    phone: (0, _model.attr)('string'),
    phoneDDD: (0, _model.attr)('string'),
    number: (0, _model.attr)('string'),
    registerToken: (0, _model.attr)('string'),
    policiesUrl: (0, _model.attr)('string'),
    thumbLogo: (0, _model.attr)('string'),
    companyName: Ember.computed.or('standardName', 'tradingName', 'name')
  });
  _exports.default = _default;
});