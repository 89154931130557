define("gestor-projetos/models/task-follow", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    user: (0, _model.belongsTo)('user'),
    task: (0, _model.belongsTo)('task')
  });
  _exports.default = _default;
});