define("gestor-projetos/pods/components/input-color/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['input-color'],
    // Services
    store: Ember.inject.service(),
    // Default values
    color: '#156FB2',
    // Multiline computed properties
    colors: Ember.A(['#156FB2', '#E83029', '#B0B21E', '#149080', '#08DDC1', '#FDFE77', '#B20A7F', '#31753B', '#CCA173', '#7C5AC2', '#627257', '#515572', '#CA9E8E', '#9A9FBF', '#EDF2F6']),
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      (true && !(this.onChange && typeof this.onChange === 'function') && Ember.assert('<InputColor> requires an `@onChange` action', this.onChange && typeof this.onChange === 'function'));
    },
    // Custom methods
    colorSpan(element, _ref) {
      let [color] = _ref;
      element.setAttribute('style', `background-color:${color};`);
    },
    // Actions
    actions: {
      setColor(color) {
        this.onChange(color);
      }
    }
  });
  _exports.default = _default;
});