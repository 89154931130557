define("gestor-projetos/validators/no-task-with-posterior-date", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NoTaskWithPosteriorDate = _base.default.extend({
    store: Ember.inject.service('store'),
    validate(value, options, model /*, attribute*/) {
      if (!value) {
        return true;
      }
      return this.store.queryRecord('task', {
        project: model.id,
        or: [{
          conclusionDate: {
            '>': value
          }
        }, {
          initialDate: {
            '>': value
          }
        }]
      }).then(task => {
        if (!task) {
          return true;
        } else {
          return 'Projeto possui tarefas com data posterior à data de término informada';
        }
      });
    }
  });
  NoTaskWithPosteriorDate.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor( /* attribute, options */
    ) {
      return [];
    }
  });
  var _default = NoTaskWithPosteriorDate;
  _exports.default = _default;
});