define("gestor-projetos/mixins/modal-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    activate() {
      this._super();
      Ember.run.next(() => {
        $(".modal-route").modal('show');
      });
    },
    actions: {
      closeModal() {
        window.history.back();
      }
    },
    deactivate() {
      $(".modal-route").modal('hide');
    }
  });
  _exports.default = _default;
});