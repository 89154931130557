define("gestor-projetos/pods/project/index/task/edit/attachments/item/component", ["exports", "ui-nela/components/ui-card", "ember-concurrency"], function (_exports, _uiCard, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _uiCard.default.extend({
    // HTML attributes
    classNames: ['border-2', 'shadow-sm'],
    // Services
    ajax: Ember.inject.service(),
    fileDownload: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Single line computed properties
    task: Ember.computed.alias('taskAttachment.task'),
    // Multiline computed properties
    downloadLink: Ember.computed('taskAttachment.url', function () {
      return `data:${this.taskAttachment.type};charset=UTF-8,${this.taskAttachment.url}`;
    }),
    allowRemove: Ember.computed('taskAttachment.user.id', 'task.{userCreate.id,userResponsible.id}', function () {
      let userId = String(this.session.user.id);
      let taskCreator = Ember.get(this, 'task.userCreate.id') === userId;
      let responsible = Ember.get(this, 'task.userResponsible.id') === userId;
      let creator = Ember.get(this, 'taskAttachment.user.id') === userId;
      return responsible || creator || taskCreator;
    }),
    // Tasks
    download: (0, _emberConcurrency.task)(function* () {
      try {
        let id = this.taskAttachment.id;
        let file = yield this.ajax.request(`/taskAttachments/${id}/signedLink?type=0`);
        this.fileDownload.downloadFile(file);
        return;
      } catch (e) {
        this.swal.catch(e);
        return;
      }
    }),
    removeAttachment: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover arquivo?');
        if (!value) {
          return;
        }
        yield this.taskAttachment.destroyRecord();
        this.toast.success('Arquivo removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});