define("gestor-projetos/router", ["exports", "gestor-projetos/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Router extends Ember.Router {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('404', {
      path: '/*path'
    });
    this.route('errors', function () {
      this.route('forbidden');
      this.route('inactive-account');
      this.route('not-found');
    });
    this.route('home', {
      path: '/'
    }, function () {
      this.route('create');
      this.route('duplicate', {
        path: ':id/duplicate'
      });
      this.route('edit', {
        path: ':project_id/edit'
      });
    });
    this.route('indicators', {
      path: 'indicators/:project_id'
    });
    this.route('login');
    this.route('project', {
      path: 'project/:project_id'
    }, function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('archived');
        this.route('badges');
        this.route('column', {
          path: 'column/:task_column_id'
        });
        this.route('gantt');
        this.route('milestone', function () {
          this.route('burndown', {
            path: ':milestone_id/burndown'
          });
          this.route('create');
          this.route('edit', {
            path: ':milestone_id/edit'
          });
          this.route('show', {
            path: ':milestone_id'
          });
        });
        this.route('options');
        this.route('settings');
        this.route('status');
        this.route('task', function () {
          this.route('create');
          this.route('edit', {
            path: ':task_id'
          }, function () {
            this.route('attachments');
            this.route('history');
            this.route('records');
            this.route('related');
          });
        });
        this.route('default-checklists', function () {
          this.route('create');
          this.route('show', {
            path: ':default_checklist_id'
          });
          this.route('edit', {
            path: ':default_checklist_id/edit'
          });
        });
      });
    });
    this.route('submit');
    this.route('task', {
      path: 'task/:task_id'
    }, function () {
      this.route('attachments');
      this.route('feedback', {
        path: 'feedback/:task_feedback_id'
      });
      this.route('history');
      this.route('records');
      this.route('related');
    });
    this.route('leaderless', function () {
      this.route('edit', {
        path: ':project_id/edit'
      });
    });
    this.route('privacy-policy');
  });
});