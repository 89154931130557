define("gestor-projetos/pods/project/index/task/edit/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    editDescription: false,
    // Single line computed properties
    project: Ember.computed.alias('task.project'),
    task: Ember.computed.alias('model'),
    taskColumn: Ember.computed.alias('task.taskColumn'),
    // Multiline computed properties
    allowEdit: Ember.computed('isTaskResponsible', 'project.usersAdmin.[]', 'session.user.id', 'task.userCreate.id', function () {
      let userId = String(this.session.user.id);
      let admin = Ember.get(this, 'project.usersAdmin').findBy('id', userId);
      let creator = Ember.get(this, 'task.userCreate.id') === userId;
      return admin || creator || this.isTaskResponsible;
    }),
    allowNextStep: Ember.computed('isTaskResponsible', 'taskColumn.allowNextColumn', function () {
      return Ember.get(this, 'taskColumn.allowNextColumn') && this.isTaskResponsible;
    }),
    isTaskResponsible: Ember.computed('session.user.id', 'task.userResponsible.id', function () {
      return Ember.get(this, 'task.userResponsible.id') === String(this.session.user.id);
    }),
    // Tasks
    archive: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.fire({
          title: 'Deseja arquivar esta tarefa?',
          text: 'Só receberão notificações os usuários que forem mencionados.',
          icon: 'question'
        });
        if (!value) {
          return;
        }
        yield this.ajax.put(`tasks/${this.task.id}/archive`);
        this.toast.success('Tarefa arquivada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Excluir tarefa?');
        if (!value) {
          return;
        }
        yield this.task.destroyRecord();
        this.toast.success('Tarefa removida');
        this.transitionToRoute('project.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    nextStep: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Finalizar esta etapa?');
        if (!value) {
          return;
        }
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        let taskHistory = this.store.createRecord('task-history', {
          user,
          date: (0, _moment.default)().toDate(),
          task: this.task,
          taskColumn: this.taskColumn,
          type: 'finished-stage'
        });
        let taskColumn = Ember.get(this, 'taskColumn.nextColumn');
        if (taskColumn) {
          Ember.set(this, 'task.taskColumn', taskColumn);
        }
        yield Ember.RSVP.all([this.task.save(), taskHistory.save()]);
        this.toast.success('Etapa concluída');
      } catch (e) {
        this.task.rollbackAttributes();
        this.swal.catch(e);
      }
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        yield (0, _emberConcurrency.timeout)(800);
        yield this.task.save();
        Ember.set(this, 'editDescription', false);
      } catch (e) {
        this.task.rollbackAttributes();
        this.swal.catch(e);
      }
    }).restartable(),
    unarchive: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Deseja desarquivar esta tarefa?');
        if (!value) {
          return;
        }
        yield this.ajax.put(`tasks/${this.task.id}/unarchive`);
        this.toast.success('Tarefa desarquivada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Custom methods
    applyCss(element) {
      element.querySelector('.modal-content').setAttribute('style', 'border-radius:2rem');
    },
    autofocus(element) {
      element.focus();
    },
    // Actions
    actions: {
      cancelDescriptionEdit() {
        let changedAttributes = this.task.changedAttributes();
        if (changedAttributes.description) {
          Ember.set(this, 'task.description', changedAttributes.description.firstObject);
        }
        Ember.set(this, 'editDescription', false);
      },
      changeEstimatedTime(unmasked, masked) {
        Ember.set(this, 'task.time', masked);
        this.save.perform();
      },
      closeModal() {
        this.transitionToRoute('project.index');
      },
      createChecklist() {
        let checklist = this.store.createRecord('checklist', {
          project: this.project,
          task: this.task,
          title: 'Novo checklist'
        });
        this.task.checklists.addObject(checklist);
      },
      setDate(attr, _ref) {
        let {
          date
        } = _ref;
        Ember.set(this, `task.${attr}`, date);
        this.save.perform();
      }
    }
  });
  _exports.default = _default;
});