define("gestor-projetos/pods/components/modal-dialogs/imposed-comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XOK2B1q+",
    "block": "{\"symbols\":[\"Modal\",\"Footer\"],\"statements\":[[5,\"ui-modal\",[[12,\"data-backdrop\",\"static\"]],[[\"@open\",\"@onCloseModal\"],[true,[28,\"action\",[[23,0,[]],\"reject\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h5\",true],[10,\"class\",\"modal-title\"],[8],[0,\"Adicione um comentário para continuar\"],[9],[0,\"\\n\\n    \"],[5,\"input-mentions\",[],[[\"@value\",\"@mentions\",\"@project\",\"@inputId\",\"@sendOnEnter\"],[[23,0,[\"text\"]],[24,[\"model\",\"options\",\"mentions\"]],[24,[\"model\",\"options\",\"project\"]],[29,[\"input-comment-task\",[24,[\"model\",\"options\",\"project\",\"id\"]]]],[28,\"action\",[[23,0,[]],\"save\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"textarea\",[[12,\"id\",[29,[\"input-comment-task\",[24,[\"model\",\"options\",\"project\",\"id\"]]]]],[12,\"class\",\"ui-scrollbar ui-textarea overflow-auto\"]],[[\"@value\",\"@placeholder\"],[[23,0,[\"text\"]],\"Adicionar comentário...\"]]],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"cancel\"]],[],[[],[]]],[0,\"\\n    \"],[6,[23,2,[\"submit\"]],[],[[\"@click\"],[[28,\"action\",[[23,0,[]],\"save\"],null]]],{\"statements\":[[0,\"Salvar\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/components/modal-dialogs/imposed-comment/template.hbs"
    }
  });
  _exports.default = _default;
});