define("gestor-projetos/services/socket-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Services
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    socket: Ember.inject.service(),
    // Default values
    areListenersInitialized: false,
    isModelRegistered: false,
    modelId: null,
    modelName: null,
    // Methods
    registerSocket(modelName, modelId) {
      Ember.setProperties(this, {
        modelName,
        modelId
      });
      if (this.socket.isSocketAuthenticated) {
        this.sendRegisterSocket();
      }
    },
    unregisterSocket() {
      this.socket.post('/sockets/unregister', {
        modelName: this.modelName,
        modelId: this.modelId
      }).then(response => {
        Ember.debug(`Socket unregister: ${response}`);
      });
    },
    sendRegisterSocket() {
      this.socket.post('/sockets/register', {
        modelName: this.modelName,
        modelId: this.modelId
      }).then(response => {
        Ember.debug(`Socket register: ${response}`);
        if (!this.areListenersInitialized) {
          this.initSocketListeners();
          Ember.set(this, 'areListenersInitialized', true);
        }
      });
    },
    initSocketListeners() {
      this.socket.on('createTask', data => {
        this.store.pushPayload({
          task: data.task
        });
        let task = this.store.peekRecord('task', data.task.id);
        let column = this.store.peekRecord('task-column', data.task.taskColumn);
        column.trigger('createTaskViaSocket', {
          task,
          count: data.count
        });
      });
      this.socket.on('moveTask', data => {
        let task = this.store.findRecord('task', data.task.id);
        let fromColumn = this.store.peekRecord('task-column', data.fromColumn.id);
        let toColumn = this.store.peekRecord('task-column', data.toColumn.id);
        Ember.set(task, 'finalizedAt', data.task.finalizedAt);
        if (data.toColumn.id === data.fromColumn.id) {
          return;
        }
        fromColumn.trigger('removeTaskViaSocket', {
          task,
          count: data.fromColumn.count
        });
        toColumn.trigger('addTaskViaSocket', {
          task,
          count: data.toColumn.count
        });
      });
      this.socket.on('taskChanged', data => {
        this.store.pushPayload(data);
      });
      this.socket.on('taskDeleted', _ref => {
        let {
          task
        } = _ref;
        let taskColumn = this.store.peekRecord('task-column', task.taskColumn);
        taskColumn.trigger('deleteTaskViaSocket', {
          task
        });
      });
      this.socket.on('taskColumnCreated', data => {
        this.store.pushPayload(data);
        let project = this.store.peekRecord('project', data.taskColumn.project);
        let taskColumn = this.store.peekRecord('task-column', data.taskColumn.id);
        project.trigger('addColumnViaSocket', taskColumn);
      });
      this.socket.on('taskColumnDeleted', _ref2 => {
        let {
          taskColumn
        } = _ref2;
        let project = this.store.peekRecord('project', taskColumn.project);
        taskColumn = this.store.peekRecord('task-column', taskColumn.id);
        project.trigger('removeColumnViaSocket', taskColumn);
      });
      this.socket.on('projectChanged', data => {
        let project = this.store.peekRecord('project', data.project);
        if (!Ember.get(project, 'isSaving')) {
          project.reload();
        }
      });
      this.socket.on('milestoneDeleted', data => {
        let milestone = this.store.peekRecord('milestone', data.milestone);
        if (milestone && !milestone.get('isDeleted')) {
          let project = Ember.get(milestone, 'project');
          project.get('milestones').removeObject(milestone);
          milestone.deleteRecord();
        }
      });
      this.socket.on('badgeDeleted', data => {
        let badge = this.store.peekRecord('badge', data.badge);
        if (badge && !badge.get('isDeleted')) {
          let project = Ember.get(badge, 'project');
          project.get('badges').removeObject(badge);
          badge.deleteRecord();
        }
      });
      this.socket.on('pushData', data => {
        this.store.pushPayload(data);
      });
      this.socket.on('hasAuthenticated', () => {
        this.sendRegisterSocket();
      });
    }
  });
  _exports.default = _default;
});