define("gestor-projetos/models/task-reminder", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    user: (0, _model.belongsTo)('user'),
    company: (0, _model.belongsTo)('company'),
    task: (0, _model.belongsTo)('task'),
    userCreate: (0, _model.belongsTo)('user'),
    text: (0, _model.attr)('string'),
    reminderDate: (0, _model.attr)('date')
  });
  _exports.default = _default;
});