define("gestor-projetos/pods/components/modal-dialogs/imposed-comment/component", ["exports", "ember-modal-service/components/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modal.default.extend({
    // Services
    modal: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Default values
    text: '',
    // Actions
    actions: {
      save() {
        if (!this.text) {
          return this.swal.warning('Adicione um comentário para continuar');
        }
        this.resolve({
          text: this.text,
          mentions: this.model.options.mentions
        });
      }
    }
  });
  _exports.default = _default;
});