define("gestor-projetos/pods/project/header/filter/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mOG9R/mn",
    "block": "{\"symbols\":[\"Dropdown\",\"&attrs\",\"&default\",\"@dropdown\"],\"statements\":[[4,\"with\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[\"Content\"]],[[12,\"class\",\"card\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"card-body header-filter__dropdown\"],[13,2],[8],[0,\"\\n      \"],[14,3,[[28,\"hash\",null,[[\"Link\"],[[28,\"component\",[\"project/header/filter/link\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gestor-projetos/pods/project/header/filter/content/template.hbs"
    }
  });
  _exports.default = _default;
});