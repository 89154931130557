define("gestor-projetos/mixins/additional-route-models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    additionalModels: function () {},
    initializeAdditionalModels: function (model, transition, queryParams) {
      var models, promise;
      models = this.additionalModels(model, transition, queryParams);
      if (models) {
        promise = Ember.RSVP.hash(models);
        this.set('_additionalModelsPromise', promise);
        return promise;
      }
    },
    setupControllerAdditionalModels: function (controller) {
      var modelsPromise;
      modelsPromise = this._additionalModelsPromise;
      if (modelsPromise) {
        modelsPromise.then(function (hash) {
          controller.setProperties(hash);
        });
      }
    },
    afterModel: function (model, transition, queryParams) {
      return this.initializeAdditionalModels(model, transition, queryParams);
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      this.setupControllerAdditionalModels(controller);
    }
  });
  _exports.default = _default;
});