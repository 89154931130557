define("gestor-projetos/pods/project/index/status/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    // Lifecycle hooks
    model() {
      let project = this.modelFor('project');
      const projectAdmins = [];
      project.usersAdmin.content.forEach(user => {
        projectAdmins.push(user.id);
      });
      const allowed = projectAdmins.includes(this.permission.user.id) || this.permission.admin;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
      return Ember.RSVP.hash({
        project,
        status: this.ajax.request(`projects/${project.id}/status`)
      });
    }
  });
  _exports.default = _default;
});