define("gestor-projetos/pods/project/index/default-checklists/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProjectIndexDefaultChecklistsIndexRoute extends Ember.Route {
    model() {
      const project = this.modelFor('project');
      return project;
    }
  }
  _exports.default = ProjectIndexDefaultChecklistsIndexRoute;
});