define("gestor-projetos/services/counter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    count: Ember.computed(function () {
      var count = localStorage.getItem('counter_count') || 0;
      count++;
      localStorage.setItem('counter_count', count);
      return count;
    }).volatile()
  });
  _exports.default = _default;
});