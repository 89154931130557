define("gestor-projetos/pods/project/task/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['mb-2'],
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    fetch: Ember.inject.service(),
    // Single line computed properties
    project: Ember.computed.alias('task.project'),
    taskColumn: Ember.computed.alias('task.taskColumn'),
    userCreate: Ember.computed.alias('task.userCreate'),
    userResponsible: Ember.computed.alias('task.userResponsible'),
    // Multiline computed properties
    isColumnResponsible: Ember.computed('session.user.id', 'taskColumn.responsibleUsers.[]', function () {
      let responsible = Ember.get(this, 'taskColumn.responsibleUsers');
      return responsible ? responsible.findBy('id', String(this.session.user.id)) : false;
    }),
    urlUserCreateAvatar: Ember.computed('userCreate', function () {
      return this.userCreate.get('avatar');
    }),
    urlUserCreateFullName: Ember.computed('userCreate', function () {
      return this.userCreate.get('fullName');
    }),
    urlUserResponsibleAvatar: Ember.computed('task.userResponsible', function () {
      return this.task.userResponsible.get('avatar');
    }),
    urlUserResponsibleFullName: Ember.computed('task.userResponsible', function () {
      return this.task.userResponsible.get('fullName');
    }),
    isProjectAdmin: Ember.computed('project.usersAdmin.[]', 'session.user.id', function () {
      let admin = Ember.get(this, 'project.usersAdmin');
      return admin ? admin.findBy('id', String(this.session.user.id)) : false;
    }),
    permissionToArchive: Ember.computed('isColumnResponsible', 'isProjectAdmin', 'session.user.id', 'task.userCreate.id', 'task.userResponsible.id', function () {
      let userId = String(this.session.user.id);
      let isTaskCreator = Ember.get(this, 'task.userCreate.id') === userId;
      let isTaskResponsible = Ember.get(this, 'task.userResponsible.id') === userId;
      return this.isProjectAdmin || this.isColumnResponsible || isTaskCreator || isTaskResponsible;
    }),
    taskTitle: Ember.computed('task.{alias,title}', function () {
      if (!this.task.alias) {
        return this.task.title;
      }
      return `(${this.task.alias}) ${this.task.title}`;
    }),
    showArchive: Ember.computed('task.archived', 'permissionToArchive', function () {
      return this.permissionToArchive && !this.task.archived;
    }),
    isPastConclusionDate: Ember.computed('task.conclusionDate', function () {
      const now = (0, _moment.default)().startOf('day');
      const conclusionDate = (0, _moment.default)(this.task.conclusionDate).startOf('day');
      return conclusionDate.isBefore(now);
    }),
    isExpired: Ember.computed('task.{conclusionDate,finalizedAt}', 'isPastConclusionDate', function () {
      if (!this.task.finalizedAt) {
        return this.isPastConclusionDate;
      }
      const finalizedAt = (0, _moment.default)(this.task.finalizedAt).startOf('day');
      const conclusionDate = (0, _moment.default)(this.task.conclusionDate).startOf('day');
      return conclusionDate.isBefore(finalizedAt);
    }),
    showUnarchive: Ember.computed.and('permissionToArchive', 'task.archived'),
    showChecklist: Ember.computed('task.{checklistProgress,checklists.length}', function () {
      return this.task.checklists.length > 0 && this.task.checklistProgress != null && this.task.checklistProgress >= 0;
    }),
    // Tasks
    archive: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.fire({
          title: 'Deseja arquivar esta tarefa?',
          text: 'Só receberão notificações os usuários que forem mencionados.',
          icon: 'question'
        });
        if (!value) {
          return;
        }
        yield this.ajax.put(`tasks/${this.task.id}/archive`);
        Ember.set(this, 'tasksQnt', this.tasksQnt - 1);
        this.toast.success('Tarefa arquivada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    unarchive: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Deseja desarquivar esta tarefa?');
        if (!value) {
          return;
        }
        yield this.ajax.put(`tasks/${this.task.id}/unarchive`);
        this.toast.success('Tarefa desarquivada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Custom methods
    colorBadge(element, _ref) {
      let [color] = _ref;
      element.setAttribute('style', `background:${color}`);
    }
  });
  _exports.default = _default;
});